import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function NotFound() {
	const { t } = useTranslation('translation');

	return (
		<div className="pageNotFound mt-4">
			<div>
				<h3>404</h3>
				<h1>{t('errors.notFound')}</h1>
				<h2>{t('errors.inconveniente')}</h2>
				<Link to="/">
					<button type="button" className="btn btn-outline-primary backHome">
						{t('errors.homePage')}
					</button>
				</Link>
			</div>
			<img src="./images/error.png" alt="Risorsa non trovata" />
		</div>
	);
}
