/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'design-react-kit';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import Currency from '../Common/Currency';
import InputConDescrizione from '../Common/InputConDescrizione';
import { paymentProcessValidation } from "../../../DataModels/DebitoSpontaneo";
import configuration from "../../../configuration";
import handleErrors from "../../../Backoffice/Utils/HandleErrors";
import useToken from "../../../Hooks/useToken";

export default function ModalePagamento(props) {
	const {
		t,
		isOpen,
		setIsOpen
	} = props;

	const [callStatus, setCallStatus] = useState('idle');
	const [downloading, setDownloading] = useState(false);

	const cartElements = useSelector((state) => state.cart);

	const { control, handleSubmit, formState, setError, setValue } = useForm({
		resolver: yupResolver(paymentProcessValidation),
		defaultValues: {
			pendenze: cartElements.map((elem) => ({
				iuv: elem?.iuv,
				codiceFiscaleEnte: elem?.ente?.intestatario?.codiceFiscale,
				pluginData: elem?.pluginData
			}))
		}
	});

	useEffect(() => {
		setCallStatus('idle');
	}, [isOpen]);

	const jwt = useToken();

	const model1Submit = async (data) => {
		setCallStatus('fetching');
		const response = await fetch(`${configuration.serverAddress}/pagamento/checkout`, {
			method: "POST",
			redirect: 'manual',
			body: JSON.stringify(data),
			headers: new Headers({
				Authorization: jwt ? `Bearer ${jwt}` : undefined,
				...configuration.defaultHeaders
			})
		});
		if (response.status === 200) {
			const resp = await response.json();
			setCallStatus('fetched');
			window.location.href = resp.url;
		}

		if (response.status >= 400) {
			const error = await response.json();
			if (error.dettaglio !== undefined) {
				error.dettaglio.forEach((errore) => setError(errore.field.split('.').pop(), {
					description: errore.description }));
			} else handleErrors(error, setError);
		}
	};

	const rows = cartElements.map((e) => (
		<tr key={`carrello-modale-${e.codice}`}>
			<td>{`${e.ente.intestatario.denominazione} - ${e.tributo.denominazione}`}</td>
			<td>{(e.dettagli ?? e.dettagli).map((d) => d.importo).reduce((v, acc) => v + acc, 0)}</td>
			{/* <td>{e.causale}</td> */}
			<td>{e.anagraficaDebitore.codiceFiscale ?? e.anagraficaDebitore.partitaIva}</td>
			<td>{((d) => d?.denominazione ?? `${d?.nome ?? ""} ${d?.cognome ?? ""}`)(e.anagraficaDebitore)}</td>
			{/* <td>{e.comune}</td> */}
			{/* <td>{e.indirizzo}</td> */}
			<td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }}>{e.note}</td>
		</tr>
	));

	const donwloadAvvisi = async () => {
		setDownloading(true);
		const response = await fetch(`${configuration.serverAddress}/debiti/avviso`, {
			method: 'POST',
			body: JSON.stringify(cartElements?.map((p) => ({ codiceEnte: p.ente.codiceEnte, iuv: p.iuv }))),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		setDownloading(false);
	};

	return (
		<Modal
			className="modale-checkout"
			isOpen={isOpen}
			toggle={() => setIsOpen(!isOpen)}
			labelledBy="riepilogo-carrello"
		>
			<ModalHeader toggle={() => setIsOpen(!isOpen)} id="riepilogo-carrello">
				{t('carrello.riepilogoCar')}
			</ModalHeader>
			<ModalBody className="py-4">
				<div className="row mt-4">
					<div className="col-12">
						<table className="w-100">
							<thead>
								<tr>
									<th>{t('carrello.tipologiaPagamento')}</th>
									<th>{t('carrello.importo')}</th>
									{/* <th>Causale</th> */}
									<th>{t('carrello.codFisPIva')}</th>
									<th>{t('carrello.denominazione')}</th>
									{/* <th>Comune di residenza</th> */}
									{/* <th>Indirizzo di residenza</th> */}
									<th>{t('carrello.note')}</th>
								</tr>
							</thead>
							<tbody>
								{rows}
							</tbody>
						</table>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12 col-md-6 col-lg-4 col-xl-3">
						<Controller
							name="cfIva"
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputConDescrizione
									titolo={t('carrello.codFisPIva')}
									obbligatorio
									tipo="text"
									nome="cfIva"
									placeholder=""
									onChange={(e) => {
										const start = e.target.selectionStart;
										const end = e.target.selectionEnd;
										e.target.value = e.target.value.toUpperCase();
										e.target.setSelectionRange(start, end);
										onChange(e.target.value.toUpperCase());
									}}
									value={value}
									errore={formState.errors.cfIva?.message}
								/>
							)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 col-xxl-3">
						<Controller
							name="email"
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputConDescrizione
									titolo={t('carrello.email')}
									obbligatorio
									tipo="email"
									nome="email"
									placeholder={t('carrello.placeholderEmail')}
									onChange={(e) => onChange(e.target.value)}
									value={value}
									errore={formState.errors.email?.message}
								/>
							)}
						/>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12">
						{t('carrello.textPagamentoModal')}
					</div>
				</div>

				{
					formState.errors.general && (
						<div className="alert alert-danger mt-2" role="alert">
							<b>{`${formState.errors.general.title}: `}</b>
							{formState.errors.general.description}
						</div>
					)
				}

			</ModalBody>
			<ModalFooter>
				<div className="totale-pagamento mr-auto">
					{t('carrello.car4')}
					<span className="totale-pagamento-importo">
						<Currency>
							{
								cartElements?.map((p) => p.dettagli.map((d) => d.importo)
									.reduce((v, acc) => v + acc, 0)).reduce((v, acc) => v + acc, 0)
							}
						</Currency>
					</span>
				</div>
				<Button color="primary" disabled={cartElements.length <= 0 || downloading} onClick={() => donwloadAvvisi()}>
					{t('carrello.scarica')}
				</Button>
				<Button color="primary" onClick={handleSubmit(model1Submit)} disabled={callStatus !== 'idle'}>
					{callStatus !== 'fetching' ? t('carrello.pagaOn') : t('carrello.carica')}
				</Button>
			</ModalFooter>
		</Modal>
	);
}
