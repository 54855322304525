/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ClickAwayListener, IconButton, useMediaQuery } from "@mui/material";
import Select from "react-select";
import HeaderMenu from './HeaderMenu';
import Logo from './Logo';
import ModaleRicerca from "../Components/Common/ModaleRicerca";
import { useFetch } from "../../Hooks/useFetch";

/* eslint-disable react/jsx-one-expression-per-line */
export default function Header() {
	const [isSticky, setIsSticky] = useState(false);
	const [ricerca, setRicerca] = useState(false);
	const [userOpen, setUserOpen] = useState(false);
	const [warningClosed, setWarningClosed] = useState(false);
	const user = useSelector((state) => state.authentication.user);
	const cartItems = useSelector((state) => state.cart.length);

	const { t, i18n } = useTranslation('translation');
	const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'it');

	//Funzione che permette di cambiare la lingua selezionata dall'utente
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('language', lng);
		setSelectedLanguage(lng);
	};

	const languageOptions = [
		{ value: 'it', label: 'it', flag: '/flags/it.png' },
		{ value: 'sard', label: 'srd', flag: '/flags/srd.png' },
		{ value: 'en', label: 'en', flag: '/flags/en.png' },
		{ value: 'es', label: 'es', flag: '/flags/es.png' },
		{ value: 'fr', label: 'fr', flag: '/flags/fr.png' }
	];

	const { data: system, status } = useFetch('/stats/systemState', null, 'GET');

	const isMobileLarge = useMediaQuery("(max-width: 522px)");
	const isTablet = useMediaQuery("(max-width: 768px)");

	useEffect(() => {
		function handeSticky() {
			const fromTop = document.documentElement.scrollTop;
			const sticky = fromTop > 86;
			setIsSticky(sticky);
		}

		window.addEventListener('scroll', handeSticky);
		return () => {
			window.removeEventListener('scroll', handeSticky);
		};
	});

	const tabletStyle = isTablet ? { marginLeft: 0, marginRight: 0, maxWidth: 'none' } : {};
	const warningLevelColor = (level) => {
		switch (level) {
			case 'info': return "#9DF1F1";
			case 'warning': return "#FEC791";
			case 'error': return "#FE9091";
			default: return "transparent";
		}
	};

	const canShowWarning = (avviso) => {
		if (!avviso?.avvisoAbilitato) return false;
		if (avviso.avvisoFine && moment.utc(avviso.avvisoFine).isBefore()) return false;
		if (avviso.avvisoInizio && moment.utc(avviso.avvisoInizio).isAfter()) return false;
		return true;
	};

	const loginButtonLarge = () => (
		<>
			{
				user
					? (
						<>
							<div className="white-color position-relative ml-auto d-flex flex-row flex-nowrap">
								<Select
									classNamePrefix="react-select"
									value={languageOptions.find((option) => option.value === selectedLanguage)}
									onChange={(selectedOption) => changeLanguage(selectedOption.value)}
									options={languageOptions}
									getOptionLabel={(e) => (
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img
												className="language-flag"
												src={e.flag}
												alt={e.label}
											/>
											{e.label}
										</div>
									)}
									isSearchable={false} /* Disabilita la possibilità di scrivere */
								/>
								<button className="button-no-style" type="button" onClick={() => setUserOpen(!userOpen)}>
									{user.intestatario.ragioneSociale ?? `${user.intestatario.nome} ${user.intestatario.cognome}`}
								</button>
								<Link className="search-link rounded-icon d-flex flex-row align-items-center flex-nowrap" to="/area-personale/carrello" aria-label="Cerca" data-toggle="modal" style={{ color: "white" }} data-target="#ricercaModal">
									<i className="fa fa-cart-plus" />
									{cartItems > 0 ? (
										<div className="cart-counter">{cartItems}</div>
									) : <></>}
								</Link>
								{ warningClosed && (
									<button className="button-no-style" type="button" onClick={() => setWarningClosed(false)}>
										<i className="fa fa-exclamation-triangle" />
									</button>
								)}
								{userOpen && (
									<ClickAwayListener onClickAway={() => setUserOpen(false)}>
										<div className="position-absolute bg-white shadow" style={{ top: "calc(100% + 20px)", left: 0 }}>
											<ul className="m-0 user-menu">
												<li><Link onClick={() => setUserOpen(false)} to="/area-personale">{t('header.tendinaUserProfilo')} <i className="fas fa-user" /></Link></li>
												<li><Link onClick={() => setUserOpen(false)} to="/autenticazione/cambiaRuolo">{t('header.tendinaUserRuolo')} <i className="fas fa-exchange-alt" /></Link></li>
												<li><Link onClick={() => setUserOpen(false)} to="/autenticazione/logout">{t('header.tendinaUserLogout')} <i className="fas fa-sign-out-alt" /></Link></li>
											</ul>
										</div>
									</ClickAwayListener>
								)}
							</div>
						</>
					)
					: (
						<div className="white-color position-relative ml-auto d-flex flex-row flex-nowrap">
							<Select
								classNamePrefix="react-select"
								value={languageOptions.find((option) => option.value === selectedLanguage)}
								onChange={(selectedOption) => changeLanguage(selectedOption.value)}
								options={languageOptions}
								getOptionLabel={(e) => (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img
											className="language-flag"
											src={e.flag}
											alt={e.label}
										/>
										{e.label}
									</div>
								)}
								isSearchable={false} /* Disabilita la possibilità di scrivere */
							/>


							<Link className="white-color" style={{ marginTop: '5px' }} to="/autenticazione">
								{t('header.accesso')}
							</Link>
							<Link className="search-link rounded-icon d-flex flex-row align-items-center flex-nowrap" to="/area-personale/carrello" aria-label="Cerca" data-toggle="modal" style={{ color: "white" }} data-target="#ricercaModal">
								<i className="fa fa-cart-plus" />
								{cartItems > 0 ? (
									<div className="cart-counter">{cartItems}</div>
								) : <></>}
							</Link>
							{ warningClosed && (
								<button className="button-no-style" type="button" onClick={() => setWarningClosed(false)}>
									<i className="fa fa-exclamation-triangle" />
								</button>
							)}
						</div>
					)
			}
		</>
	);

	const loginButtonSmall = () => (
		<>
			<Select
				classNamePrefix="react-select"
				value={languageOptions.find((option) => option.value === selectedLanguage)}
				onChange={(selectedOption) => changeLanguage(selectedOption.value)}
				options={languageOptions}
				getOptionLabel={(e) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img
							className="language-flag"
							src={e.flag}
							alt={e.label}
						/>
						{e.label}
					</div>
				)}
				isSearchable={false} /* Disabilita la possibilità di scrivere */
			/>

			{
				user
					? (
						<>
							<Link className="fas fa-user white-color ml-1" to="/area-personale" />
							<Link className="fas fa-sign-out-alt white-color ml-3" to="/autenticazione/logout" />
						</>
					)
					: <Link className="fas fa-user white-color" to="/autenticazione" />
			}
			<Link className="search-link rounded-icon d-flex flex-row align-items-center flex-nowrap" to="/area-personale/carrello" aria-label="Cerca" data-toggle="modal" style={{ color: "white" }} data-target="#ricercaModal">
				<i className="fa fa-cart-plus" />
				{cartItems > 0 ? (
					<div className="cart-counter">{cartItems}</div>
				) : <></>}
			</Link>
		</>
	);

	return (
		<header
			className={classNames({
				'it-header-wrapper': true,
				'it-header-sticky': true,
				'is-sticky': isSticky
			})}
		>
			<div className="it-header-slim-wrapper" style={{ zIndex: 1000 }}>
				<div className="container" style={{ ...tabletStyle }}>
					<div className="row" style={{ justifyContent: "space-between" }}>
						<div className="col-6 col-md-6 col-sm-3">
							<div className="it-header-slim-wrapper-content">
								<a className="navbar-brand" target="_blank" rel="noreferrer" href="https://www.regione.sardegna.it" title="Regione Autonoma della Sardegna">{t('header.logo')}</a>
							</div>
						</div>
						<div className="left-sticky-header-icons it-header-slim-wrapper-icon d-flex flex-row align-items-center text-right mr-2 pt-0">
							{
								!isMobileLarge
									? loginButtonLarge()
									: loginButtonSmall()
							}
						</div>
					</div>
				</div>
			</div>
			{canShowWarning(system) && !warningClosed && (
				<div style={{ ...tabletStyle, marginTop: "40px", marginBottom: "-40px", padding: "10px 0px", fontSize: "15px", fontWeight: "normal", color: "#000000AA", background: warningLevelColor(system.avvisoTipo) }}>
					<div className="container d-flex flex-row" style={{ columnGap: "20px" }}>
						{system.avvisoChiusura && (
							<div>
								<IconButton onClick={() => setWarningClosed(true)}>
									<i className="fa fa-times" />
								</IconButton>
							</div>
						)}
						<div>
							<b>Importante: </b>
							{system.avvisoMessaggio}
						</div>
					</div>
				</div>
			)}
			<div className="it-nav-wrapper">
				<div className="it-header-center-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="it-header-center-content-wrapper">
									<div className="it-brand-wrapper">
										<Link to={user ? "/area-personale" : "/"}>
											<div className="it-brand-text">
												<Logo />
											</div>
										</Link>
									</div>
									<div className="it-right-zone d-print-none">
										<div className="d-none d-lg-inline ">
											<img src="./images/logo-pagopa.svg" alt="PagoPA" className="pagopa-logo" />
										</div>
										<div className="d-none d-lg-inline ">
											<img src="https://www.regione.sardegna.it/immagini/61_240_20210329154006.svg" alt="Regione Autonoma della Sardegna" className="ras-logo" />
										</div>
										<div className="it-search-wrapper d-flex d-lg-none">
											<span className="d-none d-md-block">Cerca</span>
											<a className="search-link rounded-icon" href="#t" aria-label="Cerca" data-toggle="modal" onClick={() => setRicerca(!ricerca)}>
												<img src="./images/cerca.svg" alt="cerca" className="icon icon-cerca" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<HeaderMenu isSticky={isSticky} setRicerca={setRicerca} />
			</div>
			<ModaleRicerca t={t} isOpen={ricerca} setIsOpen={setRicerca} />
		</header>
	);
}
