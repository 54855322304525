/* eslint-disable max-len */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { Spin, Empty, Select, Button } from "antd";
import { Chip, ChipLabel, Icon } from "design-react-kit";

import {
	ENTE,
	fetchAllEnti,
	fetchAllStrutture,
	fetchAllTributi,
	fetchEntiByTributo,
	fetchListaEntiEnded, fetchListaStruttureEnded,
	fetchListaTributiEnded,
	fetchStruttureByEnte, fetchStruttureByTributo,
	fetchTributiByEnte,
	fetchTributiByStruttura,
	getListaEnti, getListaStrutture,
	getListaTributi, STRUTTURA,
	TRIBUTO
} from "../../../Store/Ricerca";
import { selectionArrayToObject } from "../../../Utils/Data";
import debounce from "../../../Backoffice/Utils/Debounce";

const { Option } = Select;

export default function CampoRicerca(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('translation');

	const {
		placeholder,
		onSelectionChange
	} = props;

	const [suggestion, setSuggestion] = useState(placeholder);
	const placeholderTemplates = {
		ente: t('ricerca.placeholderRicercaPag'),
		tributo: t('ricerca.tributo'),
		struttura: t('ricerca.struttura')
	};

	const [srcDatasource, setSrcDatasource] = useState([]);
	const [datasource, setDatasource] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [hasFocus, setHasFocus] = useState(false);
	const [openDropdownDisabled, setOpenDropdownDisabled] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [inputQuery, setInputQuery] = useState("");
	const [loading, setLoading] = useState(0);

	const listaEnti = useSelector(getListaEnti);
	const listaEntiEnded = useSelector(fetchListaEntiEnded);
	const listaTributi = useSelector(getListaTributi);
	const listaTributiEnded = useSelector(fetchListaTributiEnded);
	const listaStrutture = useSelector(getListaStrutture);
	const listaStruttureEnded = useSelector(fetchListaStruttureEnded);

	useEffect(() => {
		if (!listaEnti) dispatch(fetchAllEnti());
		if (!listaTributi) dispatch(fetchAllTributi());
		if (listaEnti && listaTributi) {
			const sourceData = [
				...(selectedKeys.some((v) => v.split("-")[0] === ENTE) ? [] : listaEnti),
				...(selectedKeys.some((v) => v.split("-")[0] === TRIBUTO) ? [] : listaTributi),
				...listaStrutture
			];
			setDatasource(sourceData);
			setSrcDatasource(sourceData);
		}
	}, [listaEnti, listaTributi, listaStrutture]);

	const onSearch = useCallback(debounce(500, (query) => {
		setInputQuery(query);
		setOpenMenu(query.length >= 3);

		if (selectedKeys.length === 0) {
			dispatch(fetchAllEnti({ tributo: null, query }));
			dispatch(fetchAllTributi({ ente: null, query }));
			return;
		}

		const sel = selectionArrayToObject(selectedKeys);
		if (sel.tributo && !sel.ente) {
			dispatch(fetchAllEnti({ tributo: sel.tributo.split('@')[0], query }));
		} else if (sel.ente && !sel.tributo) {
			dispatch(fetchAllTributi({ ente: sel.ente, query }));
			dispatch(fetchAllStrutture({ ente: sel.ente, query }));
		} else if (sel.ente && sel.tributo) {
			dispatch(fetchAllStrutture({ ente: sel.ente, tributo: sel.tributo, query }));
		}
	}), [JSON.stringify(selectedKeys)]);

	const onChange = (choosenItems) => {
		setDatasource([]);
		setOpenMenu(false);
		let choosenKeys = [];
		if (choosenItems.length > 0) {
			if (choosenItems.length > selectedKeys.length) {
				choosenKeys = [...selectedKeys, ...choosenItems.filter((item) => !selectedKeys.includes(item))];
			} else {
				choosenKeys = selectedKeys.filter((key) => choosenItems.includes(key));
			}
		}


		setSelectedKeys(choosenKeys);
		setSelectedItems( // ????
			// eslint-disable-next-line
			choosenKeys.map((key) => {
				const item = srcDatasource.find((i) => i.id === key);

				if (item !== undefined) return key;
			})
		);

		const sel = selectionArrayToObject(choosenItems);
		// const firstChoice = choosenItems[0];

		if (choosenItems.length < 3) setOpenDropdownDisabled(false);

		if (choosenItems.length === 0) {
			setDatasource([...listaEnti, ...listaTributi]);
			setSuggestion(placeholder);
		} else if (choosenItems.length < 3) {
			if (sel.ente) {
				setSuggestion(placeholderTemplates.tributo);
				// C'è un ente in selezione
				if (!sel.tributo && !sel.struttura) {
					setLoading((l) => l + 2);
					Promise.all([
						fetchStruttureByEnte(sel.ente),
						fetchTributiByEnte(sel.ente)
					]).then((res) => {
						const struttureByEnte = res[0];
						const tributiByEnte = res[1] ?? [];

						setLoading((l) => l - 2);

						setDatasource([...tributiByEnte, ...struttureByEnte]);
						setSrcDatasource([...listaEnti, ...listaTributi, ...tributiByEnte, ...struttureByEnte]);
					});
				} else if (!sel.tributo) {
					setLoading((l) => l + 1);
					fetchTributiByStruttura(sel.struttura).then((tributi) => {
						setLoading((l) => l - 1);
						setDatasource([...tributi]);
						setSrcDatasource([...listaEnti, ...listaTributi, ...tributi]);
					});
				} else if (!sel.struttura) {
					setSuggestion(placeholderTemplates.struttura);
					const [codeT, codeE] = sel.tributo.split('@', 2);
					setLoading((l) => l + 1);
					fetchStruttureByTributo(sel.ente, codeT).then((strutture) => {
						setLoading((l) => l - 1);
						if (strutture.length === 1) {
							onChange([...choosenItems, strutture[0].id]);
						} else {
							setDatasource([...strutture]);
							setSrcDatasource([...listaEnti, ...listaTributi, ...strutture]);
						}
					});
				} else {
					//
					// setSuggestion("Inserire struttura di riferimento...");
					//
					// Non abbiamo bisogno di scaricare la lista delle strutture, abbiamo la lista nel tributo
					// da filtrare?
					const trib = srcDatasource.find((i) => i.id === `tributo-${sel.tributo}`);
					setDatasource([...trib.strutture]);
					setSrcDatasource([...listaEnti, ...listaTributi, ...trib.strutture]);
				}
			} else if (sel.tributo) {
				setSuggestion(placeholderTemplates.ente);
				const [codeT, codeE] = sel.tributo.split('@', 2);
				fetchEntiByTributo(codeT).then((entiByTributo) => {
					setDatasource(entiByTributo);
					setSrcDatasource([...listaEnti, ...listaTributi, ...entiByTributo]);
				});
			}
		} else if (choosenItems.length === 3) setOpenDropdownDisabled(true);

		if (onSelectionChange) {
			onSelectionChange(choosenItems);
		}
	};

	const onFocusChange = (e) => {
		if (!hasFocus) setHasFocus(true);
		else if (selectedKeys.length > 0) setOpenMenu(false);
	};

	const onBlurChange = (e) => {
		setOpenMenu(false);
		const tempE = { ...e };
		if (e.target.value === undefined || e.target.value.length === 0) tempE.target = { value: null };
		if (hasFocus) setHasFocus(false);
	};

	const removeItem = (deletedKey) => {
		const newKeys = selectedKeys.filter((key) => key !== deletedKey);
		onChange(newKeys);
	};

	const removeDuplicates = (arr) => arr.filter((value, index, self) => index === self.findIndex((te) => te.value.toUpperCase() === value.value.toUpperCase()));

	const tagRender = ({ label, value, onClose }) => (
		<Chip>
			<ChipLabel>{label.props.textToHighlight}</ChipLabel>
			<Button
				onClick={() => {
					onClose();
					removeItem(value);
				}}
				close="true"
			>
				<Icon icon="it-close" />
			</Button>
		</Chip>
	);

	let labelClassName = "customSelectLabel";
	if (hasFocus || (selectedKeys && selectedKeys.length > 0)) labelClassName += " selectActive";

	const filteredData = datasource && datasource.length > 0 ? removeDuplicates(datasource.filter(
		(item) => !selectedItems.includes(item.value)
	)) : [];

	return (
		<div id="ricercaAvanzata" className="selectContent">
			<div className={labelClassName}>{suggestion ?? 'Seleziona un elemento'}</div>
			<Select
				className={openDropdownDisabled ? "customSelect selectDisabled" : "customSelect"}
				{...(openDropdownDisabled ? { disabled: true } : null)}
				tagRender={tagRender}
				mode="multiple"
				onChange={onChange}
				defaultActiveFirstOption={false}
				allowClear
				onSearch={onSearch}
				onFocus={onFocusChange}
				onBlur={onBlurChange}
				bordered={false}
				// value={selection} // La visualizzazione del chip appare sbagliata passando il valore
				filterOption={(inputValue, option) => inputValue.toLowerCase().split(" ").every((q) => option.label.toString().toLowerCase().includes(q))}
				notFoundContent={!listaEntiEnded || !listaTributiEnded || loading > 0 ? <div style={{ textAlign: "center" }}><Spin size="large" /></div> : <Empty description="Nessun risultato" />}
				// notFoundContent={<Empty description="Nessun risultato" />}
				dropdownClassName="customInputDropdown"
				dropdownStyle={{ display: openMenu ? "block" : "none" }}
			>
				{filteredData.map((item) => (
					<Option key={item.id} disabled={item.disabled} label={item.value}>
						<Highlighter searchWords={inputQuery.split(" ")} textToHighlight={item.value} highlightStyle={{ fontWeight: "bold", background: "none", padding: 0, margin: 0 }} />
					</Option>
				))}
			</Select>
		</div>
	);
}
