import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import { Tab, Tabs } from "@mui/material";

import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleWithSearch from '../Components/Common/TitleBarWithSearch';
import ListaServizi from '../Components/Servizi/ListaServizi';
import { useFetch } from "../../Hooks/useFetch";

export default function Servizi() {
	const { t } = useTranslation('translation');
	const codiceEnte = useParams()?.ente ?? '';
	const [search, setSearch] = useState('');
	const [tipoDebiti, setTipoDebiti] = useState(0);

	const ente = useFetch('/ente/query-public', null, 'POST', {
		pageSize: 1,
		page: 0,
		codiceEnte
	}).data?.data[0] ?? null;

	const breadcrumbs = [
		{ titolo: ente?.intestatario.denominazione ?? '...' },
		{ titolo: 'servizi' }
	];

	const handleChange = (event, newValue) => {
		setTipoDebiti(newValue);
	};

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleWithSearch
				titolo={ente ? `${ente.intestatario.denominazione}` : 'Tutti gli enti'}
				sottotitolo={t('enti.titoloServizi')}
				placeholderRicerca={t('enti.placeholderServizi')}
				searchChange={(e) => setSearch(e)}
			/>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Tabs value={tipoDebiti} onChange={handleChange}>
					<Tab label={t('enti.spontanei')} />
					<Tab label={t('enti.debitori')} />
					<Tab label={t('enti.tutti')} />
				</Tabs>
			</div>
			<div style={{ minHeight: "700px" }}>
				<ListaServizi t={t} searchString={search} codiceEnte={codiceEnte} spontanei={tipoDebiti} />
			</div>
		</>
	);
}
