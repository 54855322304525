// import logo from './logo.svg';
import './App.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

import Portal from './Portal';
import Backoffice from './Backoffice';
import Authentication from './Authentication';

function App() {
	return (
		<Router basename={process.env.REACT_APP_ROUTER_BASE || ''}>
			<Switch>
				<Route path="/backoffice"><Backoffice /></Route>
				<Route path="/autenticazione"><Authentication /></Route>
				<Route path="/autenticazione"><Authentication /></Route>
				<Route path="/"><Portal /></Route>
			</Switch>
		</Router>
	);
}

export default App;
