/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'design-react-kit';
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import { useMediaQuery } from "@mui/material";
import HeaderProfilo from './HeaderProfilo';
import ModaleServizio from '../CarrelloPagamento/ModaleServizio';
import InputConDescrizione from '../Common/InputConDescrizione';
import { useFetch } from "../../../Hooks/useFetch";
import Paginazione from "../Common/Paginazione";
import { removeEmptyObjects } from "../../../Utils/Object";
import DataViewer from "../Common/DataViewer";

export default function StoricoPagamenti() {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [filters, setFilters] = useState({ stato: "Eseguito" });
	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const { control, handleSubmit } = useForm();
	const { t } = useTranslation('translation');
	//!%Ufc734%!

	const isMobileTablet = useMediaQuery("(max-width: 576px)");
	const isTablet = useMediaQuery("(max-width: 768px)");
	const isLaptop = useMediaQuery("(max-width: 991px)");

	const { data, status } = useFetch('/pagamento/query/public', null, 'POST', {
		pageSize,
		page: page - 1,
		...removeEmptyObjects(filters)
	});

	const numRisultati = data?.totalResults ? data?.totalResults : 0;
	const currentPage = data?.currentPage ? data?.currentPage : 0;

	const statoPagamento = (d) => {
		switch (d.stato) {
			case "InCorso": return (<span className="stato-pagamento" style={{ background: "orange" }}><b>{t('storicoPagamenti.statoPagOrange')}</b></span>);
			case "Eseguito": return (<span className="stato-pagamento" style={{ background: "green" }}><b>{t('storicoPagamenti.statoPagGreen')}</b></span>);
			default:
			case "NonEseguito": return (<span className="stato-pagamento" style={{ background: "gray" }}><b>{t('storicoPagamenti.statoPagGray')}</b></span>);
		}
	};

	const tableHead = [t('storicoPagamenti.data'), t('storicoPagamenti.causale'), t('storicoPagamenti.codice'), t('storicoPagamenti.importo'), t('storicoPagamenti.stato'), ""];

	const tableData = data?.data.length > 0 ? data.data.map((c) => (
		{
			data: c.transazione.dataPagamento ? [moment(c.transazione.dataPagamento).format("DD/MM/yyyy"), "normal"] : "--",
			causale: [c.pendenza.debito.causale, "large"],
			iuv: [c.pendenza.iuv, "normal"],
			importo: [`${c.importo} €`, "normal"],
			stato: [statoPagamento(c), "normal"]
		}
	)) : [];

	const tableButtons = data?.data.length > 0 ? data.data.map((c) => (
		{
			button: {
				text: t('storicoPagamenti.visualizza'),
				function: () => setIsOpenServ({
					...c,
					pendenze: [c.pendenza],
					ente: c.pendenza.debito.ente,
					tributo: c.pendenza.debito.tributo,
					anagraficaDebitore: c.pendenza.debito.anagraficaDebitore
				})
			}
		}
	)) : [];

	return (
		<>
			<HeaderProfilo menuAttivo="storico" />
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				{
					!isTablet && (
						<div className="container p-0">
							<h3 className="ml-0">{t('storicoPagamenti.titoloPag')}</h3>
						</div>
					)
				}
				<div className="container p-0">
					<div className="row align-items-center mr-0 ml-0">
						<div className="col-12 col-md-6">
							<Controller
								control={control}
								name="pendenza.iuv"
								render={({ field: { onChange, value } }) => (
									<InputConDescrizione
										titolo={t('storicoPagamenti.codice')}
										value={value ?? ""}
										nome="pendenza.iuv"
										tipo="text"
										placeholder={t('storicoPagamenti.codice')}
										onChange={(e) => onChange(e.target.value)}
									/>
								)}
							/>
						</div>
						<div className="col-12 col-md-3">
							<Controller
								control={control}
								name="transazione.inizioDataPagamento"
								render={({ field: { onChange, value } }) => (
									<InputConDescrizione
										titolo={t('storicoPagamenti.dataGiorno')}
										tipo="date"
										nome="ricerca"
										placeholder={t('storicoPagamenti.dataGiorno')}
										onChange={(e) => onChange(e.target.value)}
										value={value ?? ""}
									/>
								)}
							/>
						</div>
						<div className="col-12 col-md-3">
							<Controller
								control={control}
								name="transazione.fineDataPagamento"
								render={({ field: { onChange, value } }) => (
									<InputConDescrizione
										titolo={t('storicoPagamenti.dataGiornoAl')}
										tipo="date"
										nome="ricerca"
										placeholder={t('storicoPagamenti.dataGiornoAl')}
										onChange={(e) => onChange(e.target.value)}
										value={value ?? ""}
									/>
								)}
							/>
						</div>
					</div>
					<div className="row" style={{ margin: "1rem 0 0 0" }}>
						<div className="col-12 col-md-6">
							<Controller
								control={control}
								name="stato"
								defaultValue="Eseguito"
								render={({ field: { onChange, value } }) => (
									<input
										type="checkbox"
										id="anche-errore"
										onChange={(e) => onChange(e.target.checked ? null : "Eseguito")}
										checked={value !== "Eseguito"}
									/>
								)}
							/>
							<label className="ml-2" htmlFor="anche-errore">{t('storicoPagamenti.pagAnnullati')}</label>
						</div>
						<div className="col-12 col-md-6 d-flex justify-content-end">
							<Button color="primary" style={{ width: isMobileTablet ? "100%" : "auto" }} onClick={handleSubmit((d) => setFilters(d))}>
								{t('storicoPagamenti.cerca')}
							</Button>
						</div>
					</div>
				</div>
				<div className="container p-0">
					<div className="row my-4" style={{ margin: "0" }}>
						<div className="col-12">{t('storicoPagamenti.risultato')} {numRisultati}</div>
					</div>
				</div>
				<div className={isLaptop ? "container bg-white pos-deb-container" : "container bg-white"}>
					{!data || status === "fetching"
						? (
							<>
								<div className="row mt-1 bg-white pt-4 pl-4 pr-4 pb-4 carrello-elemento-row">
									<div className="pageLoader" style={{ height: "50px", width: "50px" }} />
								</div>
							</>
						)
						: (
							<>
								<DataViewer head={tableHead} data={tableData} buttons={tableButtons} />
								<Paginazione
									paginaCorrente={currentPage + 1}
									perPagina={pageSize}
									risultatiTotali={numRisultati}
									onPageChange={(p) => setPage(p)}
								/>
							</>
						)
					}
				</div>
			</section>
			<ModaleServizio
				isOpen={isOpenServ != null}
				setIsOpen={() => { setIsOpenServ(null); }}
				paymentData={isOpenServ}
				viewOnly
			/>
		</>
	);
}
