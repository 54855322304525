/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useLocation } from "react-router-dom";
import { Button } from 'design-react-kit';
import { Controller, useForm } from "react-hook-form";
import axios from 'axios';
import { useSelector } from "react-redux";
import { CircularProgress, TextField } from "@mui/material";
import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleBar from '../Components/Common/TitleBar';

import ModaleServizio from '../Components/CarrelloPagamento/ModaleServizio';
import CampoRicercaEnte from '../Components/Common/CampoRicercaEnte';
import { selectionArrayToObject } from '../../Utils/Data';
import Captcha from "../Components/Common/Captcha";
import configuration from "../../configuration";

export default function StoricoPagamenti({ portal }) {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [requiresEnte, setRequiresEnte] = useState(false);
	const {	register, handleSubmit, formState: { errors }, clearErrors, control, setError } = useForm();
	const [loadingRicevuta, setLoadingRicevuta] = useState(false);

	const captchaRef = useRef();
	const user = useSelector((state) => state.authentication.user);
	const [captcha, setCaptcha] = useState(null);
	const { t } = useTranslation('translation');

	useEffect(() => {
		if (!isOpenServ) {
			captchaRef.current?.reset();
			setCaptcha(null);
		}
	}, [isOpenServ]);

	const location = useLocation();

	const getPagamentoLinkRicevuta = async () => {
		const pathname = location.pathname.split("/storico-pagamenti/")[1];
		setLoadingRicevuta(true);
		await fetch(
			`${configuration.serverAddress}/pagamento/query/portal/${pathname}`, {
				method: 'POST',
				headers: {
					...configuration.defaultHeaders,
					...(captcha ? { Captcha: captcha } : {})
				}
			}
		).then((response) => {
			if (response.status > 201) {
				setLoadingRicevuta(false);
				return;
			}
			setLoadingRicevuta(false);
			return response.json();
		}).then((pagamento) => {
			setLoadingRicevuta(false);
			setIsOpenServ({
				...(pagamento.pendenza.debito),
				idPayment: pagamento.stato && pagamento.stato === "Eseguito" ? pagamento.id : null,
				statoPagamento: pagamento.stato
			});
		}).catch((e) => {
			setLoadingRicevuta(false);
			captchaRef.current?.reset();
			setCaptcha(null);
			if (!requiresEnte && e.response.status === 409) setRequiresEnte(true);
			else if (e.response.status === 403) setError("general", { descrizione: t('errors.ricercaNonAutorizzata') });
			else if (e.response.status === 404) setError("general", { descrizione: t('errors.ricercaVuota') });
			else setError("general", { descrizione: t('errors.server') });
		});
	};

	const getPagamento = (data) => {
		if (data.codice && data.codfis) {
			const axiosParams = {
				method: "POST",
				url: '/api/pagamento/query/portal',
				headers: {
					"Content-Type": "application/json",
					...(captcha ? { Captcha: captcha } : {})
				},
				data: {
					pendenza: requiresEnte ? { ente: { codiceEnte: data.ente.ente } } : null,
					transazione: {
						ccp: data.codice,
						versante: {
							fiscalIdentifier: data.codfis
						}
					}
				}
			};
			axios(axiosParams)
				.then((response) => {
					const pagamento = response.data;
					setRequiresEnte(false);
					setIsOpenServ({
						...pagamento?.pendenza?.debito,
						pendenze: [pagamento?.pendenza],
						idPayment: pagamento?.id
					});
				})
				.catch((e) => {
					captchaRef.current?.reset();
					setCaptcha(null);
					if (!requiresEnte && e.response.status === 409) setRequiresEnte(true);
					else if (e.response.status === 403) setError("general", { descrizione: t('errors.ricercaNonAutorizzata') });
					else if (e.response.status === 404) setError("general", { descrizione: t('errors.ricercaVuota') });
					else setError("general", { descrizione: t('errors.server') });
				});
		} else {
			setError("general", t('errors.ricercaVuota'));
		}
	};

	const breadcrumbs = [
		{ titolo: 'storico pagamenti' }
	];

	return (
		portal ? (
			<>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
				<TitleBar titolo={t('storicoPagamenti.titoloPag')} contenutoAddizionale={t('storicoPagamenti.descrizionePag')} />
				<section id="profilo-utente" className="pt-4 pb-4 sezione-consulta">
					<div className="container p-0">
						<div>
							<form onSubmit={handleSubmit(getPagamento)}>
								<div className="row my-4">
									{requiresEnte && (
										<div className="col-3">
											<Controller
												name="ente"
												control={control}
												defaultValue=""
												rules={{
													required: true,
													validate: (v) => v.ente !== undefined
												}}
												render={({ field: { onChange } }) => (
													<CampoRicercaEnte
														placeholder={t('storicoPagamenti.placeholderEnte')}
														onSelectionChange={(e) => {
															onChange(selectionArrayToObject(e));
														}}
													/>
												)}
											/>
											{errors.ente && <div role="alert"><p style={{ color: "red" }} className="my-1">{t('errors.required')}</p></div>}
										</div>
									)}
									<div className="col-3">
										<TextField
											{...register("codfis", { required: true })}
											inputProps={{ maxLength: 16 }}
											label={t('storicoPagamenti.placeholderCodFisc')}
											variant="standard"
											className="w-100 input-con-descrizione"
										/>
										{errors.codfis && <div role="alert"><p style={{ color: "red" }} className="my-1">{t('errors.codFiscNonValido')}</p></div>}
									</div>
									<div className="flex-grow-1">
										<TextField
											{...register("codice", { required: true })}
											inputProps={{ maxLength: 35 }}
											label={t('storicoPagamenti.placeholderCodiceIUV')}
											variant="standard"
											className="w-100 input-con-descrizione"
										/>
										{errors.codice && <div role="alert"><p style={{ color: "red" }} className="my-1">{t('errors.required')}</p></div>}
									</div>
									<div className="ml-4 mt-2">
										<Button type="submit" onClick={() => clearErrors()} variant="contained" disabled={!user && !captcha} color="primary">{t('storicoPagamenti.cerca')}</Button>
									</div>
								</div>
								<div className="carrello-info-text">
									{t('storicoPagamenti.avvisoInserimentoDati')}
									<Link style={{ color: "blue" }} to="/autenticazione">
										{t('storicoPagamenti.autenticati')}
									</Link>
								</div>
								{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
							</form>
						</div>
					</div>
					{errors?.general && (
						<section className="section section-inset-shadow pb-0 pt-2 pb-5">
							<div className="container p-0">
								<div className="row my-4">
									<div className="col-12 pt-4 pl-4 pr-4 pb-4">{errors?.general?.descrizione}</div>
								</div>
							</div>
						</section>
					)}
				</section>
				<ModaleServizio
					isOpen={isOpenServ != null}
					setIsOpen={() => { setIsOpenServ(null); }}
					paymentData={isOpenServ}
					viewOnly
				/>
			</>
		) : (
			<>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
				<TitleBar titolo={t('storicoPagamenti.titoloRecupero')} contenutoAddizionale={t('storicoPagamenti.descrizioneRecuperoLink')} />
				<section id="profilo-utente" className="pb-4 sezione-consulta">
					<div className="container p-0">
						<div>
							<Button color="primary" onClick={() => getPagamentoLinkRicevuta()} style={loadingRicevuta ? { width: "200px", backgroundColor: "#303030" } : { width: "250px" }}>
								{loadingRicevuta ? <CircularProgress color="inherit" size={16} /> : "Prosegui" }
							</Button>
							{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
						</div>
					</div>
					{errors?.general && (
						<section className="section section-inset-shadow pb-0 pt-2 pb-5 mt-3">
							<div className="container p-0">
								<div className="row my-4">
									<div className="col-12 pt-4 pl-4 pr-4 pb-4">{errors?.general?.descrizione}</div>
								</div>
							</div>
						</section>
					)}
				</section>
				<ModaleServizio
					isOpen={isOpenServ != null}
					setIsOpen={() => { setIsOpenServ(null); }}
					paymentData={isOpenServ}
					viewOnly
				/>
			</>
		)
	);
}
