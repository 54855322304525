/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Logo from './Logo';

/* eslint-disable react/jsx-one-expression-per-line */
export default function Header() {
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		function handeSticky() {
			const fromTop = document.documentElement.scrollTop;
			const sticky = fromTop > 86;
			setIsSticky(sticky);
		}

		window.addEventListener('scroll', handeSticky);
		return () => {
			window.removeEventListener('scroll', handeSticky);
		};
	});

	return (
		<header
			className={classNames({
				'it-header-wrapper': true,
				'it-header-sticky': true,
				'is-sticky': isSticky
			})}
		>
			<div className="it-header-slim-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6">
							<div className="it-header-slim-wrapper-content">
								<a className="navbar-brand" target="_blank" rel="noreferrer" href="https://www.regione.sardegna.it" title="Regione Autonoma della Sardegna">Regione Autonoma della Sardegna</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="it-nav-wrapper" style={{ boxShadow: "none" }}>
				<div className="it-header-center-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="it-header-center-content-wrapper">
									<div className="it-brand-wrapper">
										<a href="/">
											<div className="it-brand-text">
												<Logo />
											</div>
										</a>
									</div>
									<div className="it-right-zone d-print-none">
										<div className="d-none d-lg-inline ">
											<img src="./images/logo-pagopa.svg" alt="PagoPA" className="pagopa-logo" />
										</div>
										<div className="d-none d-lg-inline ">
											<img src="https://www.regione.sardegna.it/immagini/61_240_20210329154006.svg" alt="Regione Autonoma della Sardegna" className="ras-logo" />
										</div>
										<div className="it-search-wrapper d-flex d-lg-none">
											<span className="d-none d-md-block">Cerca</span>
											<a className="search-link rounded-icon" href="#t" aria-label="Cerca" data-toggle="modal" data-target="#ricercaModal">
												<img src="./images/cerca.svg" alt="cerca" className="icon icon-cerca" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
