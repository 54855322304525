import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary, Button, IconButton,
	MenuItem, Select,
	Typography
} from "@mui/material";
import SingleFieldEditor from "./SingleFieldEditor";
import CampoString from "./Types/CampoString";
import CampoSelect from "./Types/CampoSelect";
import CampoCheckbox from "./Types/CampoCheckbox";
import CampoNumerico from "./Types/CampoNumerico";
import CampoImporto from "./Types/CampoImporto";
import CampoEmail from "./Types/CampoEmail";
import CampoCFIVA from "./Types/CampoCFIVA";
import CampoData from "./Types/CampoData";
import CampoMarcaBollo from "./Types/CampoMarcaBollo";

export default function FieldManager({ value = [], onChange, campi }) {
	const [expanded, setExpanded] = useState(false);
	const [selectedType, setSelectedType] = useState("CampoStringa");

	const createField = (type, d = {}) => {
		const typeCurr = d["@class"] ? d["@class"].split('.').pop() : type;
		let typeFields = {};

		if (typeCurr === "CampoSelect") {
			typeFields = {
				valori: d.valori ?? [],
				component: CampoSelect
			};
		}

		if (typeCurr === "CampoStringa") {
			typeFields = {
				maxLen: d.maxLen,
				regex: d.regex,
				regexErrorMessage: d.regexErrorMessage,
				component: CampoString
			};
		}

		if (typeCurr === "CampoCheckbox") {
			typeFields = {
				component: CampoCheckbox
			};
		}

		if (typeCurr === "CampoNumerico") {
			typeFields = {
				min: d.min,
				max: d.max,
				component: CampoNumerico
			};
		}

		if (typeCurr === "CampoImporto") {
			typeFields = {
				min: d.min,
				max: d.max,
				tipoDettaglio: d.tipoDettaglio ?? "Capitale",
				nomeDettaglio: d.nomeDettaglio ?? "Capitale del pagamento",
				codiceDettaglio: d.codiceDettaglio ?? "Capitale",
				capitoloEntrata: d.capitoloEntrata ?? "",
				component: CampoImporto
			};
		}

		if (typeCurr === "CampoEmail") {
			typeFields = {
				component: CampoEmail
			};
		}

		if (typeCurr === "CampoCFIVA") {
			typeFields = {
				type: d.valoriAmmessi ?? undefined,
				component: CampoCFIVA
			};
		}

		if (typeCurr === "CampoData") {
			typeFields = {
				component: CampoData
			};
		}
		if (typeCurr === "CampoMarcaBollo") {
			typeFields = {
				importo: d.importo,
				tipoBollo: d.tipoBollo,
				provResidenza: d.provResidenza,
				nomeVoce: d.nomeVoce,
				component: CampoMarcaBollo
			};
		}

		return {
			"@class": `it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.campi.${typeCurr}`,
			nome: d.nome ?? "nuovoCampo",
			defaultValue: d.defaultValue ?? "",
			label: d.label ?? "Nuovo campo",
			suggerimento: d.suggerimento ?? "Suggerimento del campo.",
			key: d.key ?? "nomeCampo",
			obbligatorio: d.obbligatorio ?? false,
			readonly: d.readonly ?? false,
			visible: d.visible ?? true,
			ricevuta: d.ricevuta ?? true,
			searchable: d.searchable ?? false,
			nascostoSpontaneo: d.nascostoSpontaneo ?? false,
			regole: d.regole ?? [],
			...typeFields
		};
	};

	let campiTarget = [];

	function checkTargets() {
		campiTarget = [];
		value.forEach((campo) => {
			campo.regole?.forEach((regola) => {
				regola.azioni.forEach((azione) => {
					if (!campiTarget.includes(azione.nomeCampoTarget)) {
						campiTarget.push(azione.nomeCampoTarget);
					}
				});
			});
		});
	}

	return (
		<div>
			{
				value?.map((campo, i) => (
					<Accordion
						key={`field-${i + 1}`}
						expanded={expanded === i}
						onChange={(_, isExpanded) => setExpanded(isExpanded ? i : false)}
					>
						<AccordionSummary>
							<IconButton
								size="small"
								className="mr-2"
								onClick={() => {
									checkTargets();
									if (!campiTarget.includes(campo.nome)) {
										onChange(value.filter((x) => x.nome !== campo.nome));
									} else {
										window.alert(`Il campo che stai cercando di eliminare è il Target di una Regola IF`);
									}
								}}
							>
								<i className="fas fa-fw fa-trash" />
							</IconButton>
							<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", width: '250px', lineHeight: "32px", flexShrink: 0 }}>
								<b className="mr-3">{campo.label}</b>
							</Typography>
							<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", color: 'text.secondary', lineHeight: "32px", width: "500px" }}>{campo.suggerimento}</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<SingleFieldEditor
								value={value.map((c) => createField("", c))}
								campi={campi.map((c) => createField("", c))}
								fv={campo}
								key={`field-${i + 1}`}
								fChange={(v) => onChange(value.map((f, idx) => (i === idx ? v : f)))}
							/>
						</AccordionDetails>
					</Accordion>
				))
			}

			<div className="d-inline-flex flex-row align-middle mt-4">
				<Select style={{ width: "200px" }} variant="standard" value={selectedType} onChange={(v) => setSelectedType(v.target.value)}>
					<MenuItem defaultChecked value="CampoStringa">Stringa</MenuItem>
					<MenuItem defaultChecked value="CampoSelect">Select</MenuItem>
					<MenuItem defaultChecked value="CampoCheckbox">Checkbox</MenuItem>
					<MenuItem defaultChecked value="CampoNumerico">Numerico</MenuItem>
					<MenuItem defaultChecked value="CampoImporto">Importo</MenuItem>
					<MenuItem defaultChecked value="CampoEmail">Email</MenuItem>
					<MenuItem defaultChecked value="CampoCFIVA">Codice fiscale o partita IVA</MenuItem>
					<MenuItem defaultChecked value="CampoData">Data</MenuItem>
					<MenuItem defaultChecked value="CampoBelfiore">Belfiore</MenuItem>
					<MenuItem defaultChecked value="CampoMarcaBollo">Marca da bollo</MenuItem>
				</Select>
				<IconButton onClick={() => onChange([...value, createField(selectedType)])}><i className="fas fa-fw fa-plus-circle" /></IconButton>
			</div>
		</div>
	);
}
