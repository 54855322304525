/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import { Button } from "design-react-kit";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { selectionArrayToObject, selectionObjectToArray } from "../../../Utils/Data";
import CampoRicerca from "./CampoRicerca";
import { RisultatiRicercaPagamentoRedirect } from "./RisultatiRicercaPagamento";
import { useFetch } from "../../../Hooks/useFetch";
import CampoRicercaEnte from "./CampoRicercaEnte";
import InputConDescrizione from "./InputConDescrizione";
import ModaleServizio from "../CarrelloPagamento/ModaleServizio";
import Captcha from "./Captcha";

export default function RicercaGenerale({ onClose }) {
	const { t } = useTranslation('translation');

	const tipiRicerca = [
		{
			tipo: 'servizio',
			nome: t('ricercaGenerale.entePagamento'),
			placeholder: [t('ricercaGenerale.placeholderEntePag')]
		},
		{
			tipo: 'iuvAvviso',
			nome: t('ricercaGenerale.avvisoRicev'),
			placeholder: [t('ricercaGenerale.placeholderEnteCreditore'), t('ricercaGenerale.placeholderCodiceAvviso'), t('ricercaGenerale.placeholderCodiceFiscale')]
		}
	];
	const [tipoRicerca, setTipoRicerca] = useState(tipiRicerca[0]);
	const isMobileMedium = useMediaQuery("(max-width: 375px)");
	const isMobileLarge = useMediaQuery("(max-width: 425px)");
	const isTablet = useMediaQuery("(max-width: 768px)");

	const elementiMenu = tipiRicerca.map((m) => (
		<li className="nav-item" key={`tipo-ricerca-${m.tipo}`} onClick={() => { setTipoRicerca(m); }}>
			<span
				className={classNames({
					'nav-link': true,
					active: tipoRicerca.tipo === m.tipo
				})}
				style={{
					justifyContent: isMobileMedium ? "left" : "center",
					cursor: "pointer"
				}}
			>
				{m.nome}
			</span>
		</li>
	));

	const RicercaIuvAvviso = useCallback(() => {
		const [details, setDetails] = useState(null);
		const [isOpenServ, setIsOpenServ] = useState(null);
		const [requiresEnte, setRequiresEnte] = useState(false);

		const {	handleSubmit, formState: { errors }, clearErrors, control, setError } = useForm();
		const user = useSelector((state) => state.authentication.user);
		const [captcha, setCaptcha] = useState(null);
		const captchaRef = useRef();

		useEffect(() => {
			if (!isOpenServ) {
				captchaRef.current?.reset();
				setCaptcha(null);
			}
		}, [isOpenServ]);

		const getPagamento = (data) => {
			setError(null);
			if (data.codice && data.codiceFiscale) {
				const axiosParams = {
					method: "POST",
					url: '/api/pagamento/query/portal',
					headers: {
						"Content-Type": "application/json",
						...(captcha ? { Captcha: captcha } : {})
					},
					data: {
						pendenza: requiresEnte ? { ente: { codiceEnte: data.ente } } : null,
						transazione: {
							ccp: data.codice,
							versante: {
								fiscalIdentifier: data.codiceFiscale
							}
						}
					}
				};
				axios(axiosParams)
					.then((response) => {
						const pagamento = response.data;
						setError(null);
						setRequiresEnte(false);
						setIsOpenServ({
							...pagamento?.pendenza?.debito,
							pendenze: [pagamento?.pendenza],
							idPayment: pagamento?.id
						});
					}).catch((e) => {
						captchaRef.current?.reset();
						setCaptcha(null);
						if (!requiresEnte && e.response.status === 409) {
							setError("general", { descrizione: t('errors.troppiPagamenti') });
							setRequiresEnte(true);
						}
						else if (e.response.status === 403) setError("general", { descrizione: t('errors.ricercaNonAutorizzata') });
						else if (e.response.status === 404) setError("general", { descrizione: t('errors.ricercaVuota') });
						else setError("general", { descrizione: t('errors.server') });
					});
			} else {
				setError("general", { descrizione: t('errors.debitoPag') });
			}
		};

		const getDebito = (data) => {
			if (data.codice && data.codiceFiscale) {
				const axiosParams = {
					method: "GET",
					url: !requiresEnte
						? `/api/debiti/iuv/${data.codice}?cod_fiscale=${data.codiceFiscale}`
						: `/api/debiti/iuv/${data.ente}/${data.codice}?cod_fiscale=${data.codiceFiscale}`,
					headers: {
						"Content-Type": "application/json",
						...(captcha ? { Captcha: captcha } : {})
					}
				};
				axios(axiosParams)
					.then((response) => {
						const pagamento = response.data;
						if (pagamento.pagato) getPagamento(data);
						else {
							setRequiresEnte(false);
							setIsOpenServ(pagamento);
						}
					})
					.catch((e) => {
						captchaRef.current?.reset();
						setCaptcha(null);
						if (!requiresEnte && e.response.status === 409) setRequiresEnte(true);
						else if (e.response.status === 403) setError("general", { descrizione: t('errors.ricercaNonAutorizzata') });
						else if (e.response.status === 404) setError("general", { descrizione: t('errors.ricercaVuota') });
						else setError("general", { descrizione: t('errors.server') });
					});
			} else {
				setError("general", t('errors.ricercaVuota'));
			}
		};

		return (
			<>
				<div>
					<form onSubmit={handleSubmit(getDebito)} className="row">
						{requiresEnte && (
							<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"}>
								<Controller
									name="ente"
									control={control}
									defaultValue=""
									rules={{
										required: true
									}}
									render={({ field: { onChange } }) => (
										<CampoRicercaEnte
											placeholder={tipoRicerca.placeholder[0]}
											onSelectionChange={(c) => {	onChange(selectionArrayToObject(c).ente); }}
										/>
									)}
								/>
								{errors.ente && <div role="alert"><p style={{ color: "red" }} className="my-1">{t('errors.campoEnte')}</p></div>}
							</div>
						)}
						<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"}>
							<Controller
								name="codiceFiscale"
								control={control}
								defaultValue=""
								rules={{
									required: true
								}}
								render={({ field: { onChange } }) => (
									<InputConDescrizione
										titolo=""
										nome="codiceFiscale"
										tipo="text"
										placeholder={tipoRicerca.placeholder[2]}
										onChange={(e) => {
											e.target.value = e.target.value.toUpperCase();
											onChange(e.target.value);
										}}
									/>
								)}
							/>
							{errors.codiceFiscale && <div role="alert"><p style={{ color: "red" }} className="my-1">{t('errors.campoCF')}</p></div>}
						</div>
						<div className="flex-grow-1 pt-4 pl-4 pr-4 pb-4">
							<Controller
								name="codice"
								control={control}
								defaultValue=""
								rules={{
									required: true
								}}
								render={({ field: { onChange } }) => (
									<InputConDescrizione
										titolo=""
										nome="codice"
										tipo="text"
										placeholder={tipoRicerca.placeholder[1]}
										onChange={onChange}
									/>
								)}
							/>
							{errors.codice && <div role="alert"><p style={{ color: "red" }} className="my-1">{t('errors.campoIUV')}</p></div>}
						</div>
						<div className={isMobileLarge ? "flex-grow-1 pt-4 pl-4 pr-4 pb-4" : "ml-4 mt-2 pt-4 pl-4 pr-4 pb-4"}>
							<Button style={isMobileLarge ? { width: "100%" } : {}} color="primary" type="submit" disabled={!user && !captcha} onClick={() => clearErrors()}>
								{t('ricerca.ricerca')}
							</Button>
						</div>
						<br />
					</form>
					<div className="carrello-info-text">
						{t('ricerca.notaAvviso')}
						{!user && t('ricerca.oppure')}
						{!user && (
							<Link style={{ color: "blue" }} to="/autenticazione">
								{t('ricerca.autenticati')}
							</Link>
						)}
					</div>
					<div className="pl-3 mt-4 mb-5">
						<span role="button" className="link" onClick={() => setDetails(!details)}>
							<h6>
								<i className={`fa fa-fw ${details ? "fa-chevron-down" : "fa-chevron-right"} mr-2`} />
								{t('ricercaGenerale.titoloAvvisoUtente')}
							</h6>
						</span>
						{
							details && (
								<>
									{t('ricercaGenerale.avvisoUtente1')}

									<br />
									{t('ricercaGenerale.avvisoUtente2')}
								</>
							)
						}
					</div>
					{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
					{errors?.general && (
						<section className="section section-inset-shadow pb-0 pt-2 pb-5">
							<div className="container p-0">
								<div className="row my-4">
									<div className="col-12 pt-4 pl-4 pr-4 pb-4">{errors?.general?.descrizione}</div>
								</div>
							</div>
						</section>
					)}
					<ModaleServizio
						isOpen={isOpenServ != null}
						setIsOpen={() => { setIsOpenServ(null); }}
						paymentData={isOpenServ}
						viewOnly={isOpenServ?.pagato}
					/>
				</div>
			</>
		);
	}, [tipoRicerca]);

	const RicercaIuv = useCallback(() => {
		const [details, setDetails] = useState(null);
		const [isOpenServ, setIsOpenServ] = useState(null);
		const [selection, setSelection] = useState({});
		const [noResult, setNoResult] = useState(null);

		const getPagamento = () => {
			setNoResult(null);
			if (selection.ente && selection.codice && selection.codfis) {
				const axiosParams = {
					method: "GET",
					url: `/api/debiti/iuv/${selection.ente}/${selection.codice}/?cod_fiscale=${selection.codfis}`,
					headers: { "Content-Type": "application/json" }
				};
				axios(axiosParams)
					.then((response) => {
						const pagamento = response.data;
						setNoResult(false);
						setIsOpenServ({
							ente: selection.ente,
							iuv: selection.codice,
							codfis: selection.codfis,
							idPayment: pagamento.stato && pagamento.stato === "Eseguito" ? pagamento.id : null
						});
					})
					.catch((e) => {
						setNoResult(true);
					});
			} else {
				setNoResult(true);
			}
		};

		return (
			<>
				<div className="row pl-2 pr-2">
					<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"} style={{ minWidth: "200px" }}>
						<CampoRicercaEnte
							placeholder="Ente"
							onSelectionChange={(c) => {
								setSelection({ ...selection, ...selectionArrayToObject(c) });
							}}
						/>
					</div>
					<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"} style={{ minWidth: "200px" }}>
						<InputConDescrizione
							titolo=""
							nome="codice_fiscale"
							tipo="text"
							placeholder="Codice fiscale"
							onChange={(e) => {
								const newSel = selection;
								newSel.codfis = e.target.value;
								setSelection(newSel);
							}}
						/>
					</div>
					<div className="flex-grow-1 pt-4 pl-4 pr-4 pb-4" style={{ maxWidth: "100%", flex: "1 0", minWidth: "200px" }}>
						<InputConDescrizione
							titolo=""
							nome="codice"
							tipo="text"
							placeholder="IUV"
							onChange={(e) => {
								const newSel = selection;
								newSel.codice = e.target.value;
								setSelection(newSel);
							}}
						/>
					</div>
					<div className={isMobileLarge ? "flex-grow-1 pt-4 pl-4 pr-4 pb-4" : "ml-4 mt-2 pt-4 pl-4 pr-4 pb-4"}>
						<Button color="primary" onClick={getPagamento}>
							{t('ricerca.ricerca')}
						</Button>
					</div>
				</div>
				{noResult === true && (
					<div className="container p-0">
						<div className="row my-4">
							<div className="col-12">{t('ricerca.nessunRisultato')}</div>
						</div>
					</div>
				)}
				<div className="pl-3 mt-4">
					<span role="button" className="link" onClick={() => setDetails(!details)}>
						<h6>
							<i className={`fa fa-fw ${details ? "fa-chevron-down" : "fa-chevron-right"} mr-2`} />
							{t('ricercaGenerale.titoloAvvisoUtente')}
						</h6>
					</span>
					{
						details && (
							<>
								{t('ricercaGenerale.avvisoUtente1')}
								<br />
								{t('ricercaGenerale.avvisoUtente2')}
							</>
						)
					}
				</div>
				<ModaleServizio
					isOpen={isOpenServ != null}
					setIsOpen={() => { setIsOpenServ(null); }}
					paymentData={isOpenServ}
				/>
			</>
		);
	}, []);

	const RicercaCodiceTransazione = useCallback(() => {
		const [details, setDetails] = useState(false);
		const [isOpenServ, setIsOpenServ] = useState(null);
		const [selection, setSelection] = useState({});
		const [noResult, setNoResult] = useState(null);

		const getPagamento = () => {
			setNoResult(null);
			if (selection.ente && selection.codice && selection.codfis) {
				const axiosParams = {
					method: "POST",
					url: '/api/pagamento/query/public',
					headers: { "Content-Type": "application/json" },
					data: {
						ente: { codiceEnte: selection.ente },
						transazione: { ccp: selection.codice }
					}
				};
				axios(axiosParams)
					.then((response) => {
						const pagamento = response.data;
						setNoResult(false);
						setIsOpenServ({
							ente: selection.ente,
							iuv: selection.codice,
							idPayment: pagamento.stato && pagamento.stato === "Eseguito" ? pagamento.id : null
						});
					})
					.catch((e) => {
						setNoResult(true);
					});
			} else {
				setNoResult(true);
			}
		};
		return (
			<>
				<div className="row pl-2 pr-2">
					<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"} style={{ minWidth: "200px" }}>
						<CampoRicercaEnte
							placeholder="Ente"
							onSelectionChange={(c) => {
								setSelection({ ...selection, ...selectionArrayToObject(c) });
							}}
						/>
					</div>
					<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"} style={{ minWidth: "200px" }}>
						<InputConDescrizione
							titolo=""
							nome="codfis"
							tipo="text"
							placeholder="Codice fiscale"
							onChange={(e) => {
								setSelection({ ...selection, codfis: e.target.value });
							}}
						/>
					</div>
					<div className="flex-grow-1 pt-4 pl-4 pr-4 pb-4" style={{ maxWidth: "100%", flex: "1 0", minWidth: "200px" }}>
						<InputConDescrizione
							titolo=""
							nome="codice"
							tipo="text"
							placeholder="Codice pagamento"
							onChange={(e) => {
								const newSel = selection;
								newSel.codice = e.target.value;
								setSelection(newSel);
							}}
						/>
					</div>
					<div className={isMobileLarge ? "flex-grow-1 pt-4 pl-4 pr-4 pb-4" : "ml-4 mt-2 pt-4 pl-4 pr-4 pb-4"}>
						<Button color="primary" onClick={getPagamento}>
							Cerca
						</Button>
					</div>
				</div>
				{noResult === true && (
					<div className="container p-0">
						<div className="row my-4">
							<div className="col-12">{t('ricerca.nessunRisultato')}</div>
						</div>
					</div>
				)}
				<div className="pl-3 mt-4">
					<span role="button" className="link" onClick={() => setDetails(!details)}>
						<h6>
							<i className={`fa fa-fw ${details ? "fa-chevron-down" : "fa-chevron-right"} mr-2`} />
							{t('ricercaGenerale.codiceTransazione')}
						</h6>
					</span>
					{
						details && (
							<>
								{t('ricercaGenerale.codiceTransDescr1')}

								<br />
								{t('ricercaGenerale.codiceTransDescr2')}

							</>
						)
					}
				</div>
				<ModaleServizio
					isOpen={isOpenServ != null}
					setIsOpen={() => { setIsOpenServ(null); }}
					paymentData={isOpenServ}
					viewOnly
				/>
			</>
		);
	}, []);

	const RicercaServizio = useCallback(() => {
		const [selection, setSelection] = useState({});
		const selectionArray = selectionObjectToArray(selection);

		const { data } = useFetch(`/struttura/query-public`, null, 'POST', {
			ente: { codiceEnte: selection.ente },
			tributo: { codiceTributo: selection.tributo?.split('@')[0] },
			senzaTributi: false
		});

		if (selection.ente && selection.tributo
			&& ((data?.totalResults ?? 0) === 0 || selection.struttura)) {
			onClose();
			return <RisultatiRicercaPagamentoRedirect selection={selection} />;
		}

		return (
			<>
				<div className="row" style={{ paddingBottom: 59 }}>
					<div className="col-12 pt-4 pl-4 pr-4 pb-4">
						<CampoRicerca
							placeholder={tipoRicerca.placeholder[0]}
							selection={selectionArray}
							onSelectionChange={(c) => { setSelection(selectionArrayToObject(c)); }}
						/>
					</div>
				</div>
			</>
		);
	}, [tipoRicerca]);

	return (
		<div>
			<div className="boxRicerca pl-4 pr-4">
				<div className="row">
					<div className="col-12 pl-4 pr-4 pt-4 pb-2">
						<ul className="nav nav-tabs nav-item-list-genSearch">
							{elementiMenu}
						</ul>
					</div>
				</div>
				{
					(() => {
						switch (tipoRicerca.tipo) {
							case "servizio": return (<RicercaServizio />);
							//case "pagamento": return (<RicercaCodiceTransazione />);
							//case "pendenza": return (<RicercaIuv />);
							case "iuvAvviso": return (<RicercaIuvAvviso />);
							default: return (<></>);
						}
					})()
				}
			</div>
		</div>
	);
}
