import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'design-react-kit';
import { useDispatch, useSelector } from "react-redux";

import { TextField, useMediaQuery } from "@mui/material";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import HeaderProfilo from './HeaderProfilo';
import ModalePagamento from '../CarrelloPagamento/ModalePagamento';
import ModaleServizio from '../CarrelloPagamento/ModaleServizio';
import Currency from '../Common/Currency';
import DataViewer from "../Common/DataViewer";
import { removeFromCart } from "../../../Store/Cart";
import ConfirmModal from "../../../Backoffice/Components/ConfirmModal";

export default function Carrello() {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenServ, setIsOpenServ] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [confirmDelete, setConfirmDelete] = useState(null);
	const [debitToRemove, setDebitToRemove] = useState(null);

	const dispatch = useDispatch();
	const history = useHistory();

	const cartElements = useSelector((state) => state.cart);

	const isTablet = useMediaQuery("(max-width: 768px)");
	const isLaptop = useMediaQuery("(max-width: 991px)");
	const { t } = useTranslation('translation');


	const tableHead = [t('carrello.tipologiaPagamento'), t('carrello.beneficiario'), t('carrello.importo'), t('carrello.causale'), t('carrello.codFisPIva'), t('carrello.denominazione'), ""];

	const tableData = cartElements.length > 0
		? cartElements.map((c) => (
			{
				tipoPagamento: [c.tributo.denominazione, "normal"],
				beneficiario: [c.ente.intestatario.denominazione, "normal"],
				importo: [`${(c.dettagli ?? c.pendenze[0].dettagli).map((d) => d.importo).reduce((v, acc) => v + acc, 0)} €`, "normal"],
				causale: [c.causale, "large"],
				cfIva: [c.anagraficaDebitore.codiceFiscale ?? c.anagraficaDebitore.partitaIva, "normal"],
				denominazione: [(((d) => d?.denominazione ?? `${d?.nome ?? ""} ${d?.cognome ?? ""}`)(c.anagraficaDebitore)), "normal"]
			}
		)) : [];

	const tableButtons = cartElements.length > 0
		? cartElements.map((c) => (
			{
				button1: {
					text: t('carrello.alertElimina'),
					function: () => {
						setDebitToRemove(c);
						setConfirmDelete(true);
					}
				},
				button2: {
					text: t('carrello.alertVisualizza'),
					function: () => {
						setModalData(c);
						setIsOpenServ(!isOpenServ);
					} }
			})) : [];

	console.log(cartElements?.map((p) => (p.dettagli ?? p.pendenze[0].dettagli)).flat().map((d) => d.importo)
		.reduce((v, acc) => v + acc, 0));
	return (
		<>
			<HeaderProfilo menuAttivo="carrello" />
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				<div className="container p-0">
					{
						!isTablet && (
							<h3 className="ml-0">{t('carrello.car1')}</h3>
						)
					}
					<div className="carrello-info d-flex flex-row align-items-center mb-3">
						<div className="carrello-info-text mr-3">
							{t('carrello.car2')}
						</div>
						<Button color="primary" disabled={cartElements.length >= 5} onClick={() => history.push("/")}>
							{t('carrello.pagCarrelloAggiungi')}
						</Button>
					</div>
				</div>
				<div className="container">
					<div className="row mt-1 bg-white pt-4 pl-4 pr-4 carrello-elemento-row">
						{cartElements.length <= 0 && (
							<div className="text-center mt-5 mb-5 w-100">
								<h4>{t('carrello.car3')}</h4>
							</div>
						)}
						<DataViewer
							head={tableHead}
							data={tableData}
							buttons={tableButtons}
							tipo="cart"
						/>
					</div>
					<div className="row bg-white pb-4 pl-4 pr-4 carrello-footer">
						<div className="totale-pagamento">
							{t('carrello.car4')}
							<span className="totale-pagamento-importo">
								<NumberFormat
									value={cartElements?.map((p) => (p.dettagli ?? p.pendenze[0].dettagli)).flat().map((d) => d.importo)
										.reduce((v, acc) => v + acc, 0)}
									displayType="text"
									style={{ flexGrow: 1 }}
									prefix="€ "
									decimalScale={2}
									decimalSeparator=","
									thousandSeparator="."
								/>
							</span>
						</div>
						<Button color="primary" disabled={cartElements.length <= 0} onClick={() => setIsOpen(!isOpen)}>
							{t('carrello.pagCarrelloPaga')}
						</Button>
						<ModalePagamento t={t} isOpen={isOpen} setIsOpen={setIsOpen} />
					</div>
				</div>
			</section>
			<ConfirmModal
				open={confirmDelete}
				title={t('carrello.titoloAlert')}
				text={t('carrello.alertRimozione')}
				onConfirm={() => {
					dispatch(removeFromCart(debitToRemove));
					setConfirmDelete(false);
				}}
				onDeny={() => {
					setConfirmDelete(false);
					setDebitToRemove(null);
				}}
				confirmText={t('carrello.alertElimina')}
			/>
			<ModaleServizio
				isOpen={isOpenServ}
				setIsOpen={setIsOpenServ}
				paymentData={modalData}
				viewOnly
			/>
		</>
	);
}
