/* eslint-disable class-methods-use-this */
/* eslint-disable no-use-before-define */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable function-paren-newline */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { TextField, Divider, Grid, Typography, Switch } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import moment, { isDate } from "moment";
import FieldManager from "./FieldManager";
import { getFieldFromData } from "./SingleFieldEditor";
import RenderInputComponent, { applyFieldRules } from "./Engine";
import PortalInputMap from "./Interfaces/PortalInputMap";
import BackofficeInputMap from "./Interfaces/BackofficeInputMap";
import AggregatoriManager from "./AggregatoriManager";
import BasePlugin from "../BasePlugin";

export default class CampiPlugin extends BasePlugin {
	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.CampiConfiguration",
			nome: "Gestione campi",
			codicePlugin: "CAMPI",
			ordine: this.configuration.index,
			descrizione: "Gestisce la configurazione di campi personalizzabili.",
			templateCausale: "",
			abilitaCSV: false,
			campi: []
		};
	}


	/**
	 * Trasforma una mappa di valori <nomeCampo, valore> in un debito o modifica il debito già
	 * creato precedentemente da altri plugin.
	 * @param fields La mappa di valori ottenuta dal form semplificato.
	 * @param baseDebit Il debito da modificare.
	 * @returns {*} Il debito modificato.
	 */
	backofficePaymentEditorSubmit(fields, baseDebit) {
		let capitaleRimosso = false;
		this.configuration.campi.forEach((field) => {
			const fieldType = field["@class"].split('.').pop();
			if (fieldType === "CampoImporto" && field.nomeDettaglio && field.tipoDettaglio) {
				const dettFoundIndex = baseDebit.pendenze[0].dettagli.findIndex((d) => d.codiceDettaglio === "Capitale" && d.tipo === "Capitale");
				if (dettFoundIndex >= 0 && !capitaleRimosso) {
					baseDebit.pendenze[0].dettagli.splice(dettFoundIndex, 1);
					capitaleRimosso = true;
				}

				baseDebit.pendenze[0].dettagli.push({
					tipo: field.tipoDettaglio,
					codiceDettaglio: field.codiceDettaglio,
					stato: "Attiva",
					descrizione: field.nomeDettaglio,
					importo: Number.parseFloat(fields[field.nome]),
					capitoloEntrata: field.capitoloEntrata,
					"@class": "it.ras.pagopa.shared.dto.pendenze.DettaglioNewDto"
				});
			}
		});

		return baseDebit;
	}

	/**
	 * Ritorna la struttura di validazione per il form di questo plugin nel portale.
	 * @param tributo Le informazioni del tributo.
	 * @returns {*}
	 */
	portalPaymentValidation(t) {
		const template = {};

		this.configuration.campi?.filter((field) => !field.nascostoSpontaneo)?.forEach((field) => {
			const fieldType = field["@class"].split('.').pop();
			switch (fieldType) {
				case "CampoCheckbox":
					template[field.nome] = yup.bool().default(false);
					break;
				case "CampoNumerico":
					template[field.nome] = yup.number().default(0);
					if (field.max !== null) template[field.nome] = template[field.nome].max(field.max, t('errors.valoreMaxConsentito', { max: field.max }));
					if (field.min !== null) template[field.nome] = template[field.nome].min(field.min, t('errors.valoreMinConsentito', { min: field.min }));
					break;
				case "CampoSelect":
					template[field.nome] = yup.string().default("");
					break;
				case "CampoStringa":
					template[field.nome] = yup.string().nullable().default(field.defaultValue ?? "");
					if (field.maxLen !== null) template[field.nome] = template[field.nome].max(field.maxLen, t('errors.lenMaxConsentita', { max: field.maxLen }));
					break;
				case "CampoImporto":
					template[field.nome] = yup.number().typeError(t('errors.importoNonValido')).default(field.defaultValue ?? 0.01);
					if (field.max !== null) template[field.nome] = template[field.nome].max(field.max, t('errors.valoreMaxConsentito', { max: field.max }));
					template[field.nome] = template[field.nome].min(field.min ?? 0.01, t('errors.valoreMinConsentito', { min: field.min ?? 0.01 }));
					break;
				case "CampoEmail":
					template[field.nome] = yup.string().email(t('errors.formatoEmail')).default("");
					break;
				case "CampoCFIVA":
					template[field.nome] = yup.object().shape({
						personaFisica: yup.boolean(),
						nome: yup.string().default("").when("personaFisica", { is: true, then: yup.string().required(t('errors.campoNome')) }),
						cognome: yup.string().default("").when("personaFisica", { is: true, then: yup.string().required(t('errors.campoCognome')) }),
						cfIva: yup.string().default("").when("personaFisica", { is: true, then: yup.string().default("").required(t('errors.required')) }),
						denominazione: yup.string().default("").when("personaFisica", { is: false, then: yup.string().required(t('errors.campoDenominazione')) }),
						partitaIva: yup.string().default("").when(["personaFisica", "codiceFiscale"], { is: (a, b) => !a && (!b || b === ""), then: yup.string().required(t('errors.pIvaCodFisc')) }),
						codiceFiscale: yup.string().default("").when(["personaFisica", "partitaIva"], { is: (a, b) => !a && (!b || b === ""), then: yup.string().required(t('errors.pIvaCodFisc')) })
					}, [['codiceFiscale', 'partitaIva']]);
					break;
				case "CampoData":
					template[field.nome] = yup.date()
						.transform((value, originalValue) => {
							const parsedDate = isDate(originalValue)
								? originalValue
								: moment(originalValue, "DD-MM-YYYY").toDate();

							return new Date(Date.UTC(
								parsedDate.getFullYear(),
								parsedDate.getMonth(),
								parsedDate.getDate(),
								parsedDate.getHours(),
								parsedDate.getMinutes()
							));
						});
					break;
				case "CampoMarcaBollo":
					break;
				default:
					template[field.nome] = yup.string();
			}

			if (field.obbligatorio) template[field.nome] = template[field.nome].required(t('errors.required'));
		});
		return yup.object().shape(template);
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel portale.
	 * @returns {JSX.Element}
	 */
	portalPaymentForm({ config }) {
		const [configuration, setConfiguration] = useState(config);
		useEffect(() => setConfiguration(config), []);

		const { formState, setValue, resetField } = useFormContext();

		return configuration?.campi?.sort((a, b) => a.indice - b.indice)?.filter((field) => !field.nascostoSpontaneo)?.map((field) => {
			const fieldType = field["@class"].split('.').pop();
			const inputHandler = PortalInputMap[fieldType];
			return (
				<Controller
					key={field.nome}
					name={field.nome}
					required={field.obbligatorio}
					render={({ field: { onChange, value } }) => (
						<RenderInputComponent
							resetField={resetField}
							onChange={onChange}
							value={value}
							configuration={configuration}
							setValue={setValue}
							setConfiguration={setConfiguration}
							inputHandler={inputHandler}
							field={field}
							error={formState.errors[field.nome]}
							scrollTo={field.nome in formState.errors}
						/>
					)}
				/>
			);
		});
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	portalPaymentSubmit({ formValues, pluginData }) {
		return { formValues, pluginData };
	}

	/**
	 * Renderizza il riepilogo dei dati di pagamento.
	 * @returns {JSX.Element}
	 */
	paymentSummary({ t, debit, config, data, path }) {
		const [configuration, setConfiguration] = useState(config);

		useEffect(() => {
			config.campi.forEach((campo) => {
				applyFieldRules({
					setValue: () => {},
					resetField: () => {},
					setConfiguration,
					configuration
				}, campo.regole, data?.valueMap[campo.nome]);
			});
		}, [config, debit]);

		const overflowStyle = { overflowWrap: 'break-word' };

		return (
			<div className="dettaglio-pagamento row">
				{
					configuration.campi.filter((campo) => campo.visible).map((field) => {
						const titolo = t(`tributi.${field.key}`, { defaultValue: field.label });

						const fieldData = data?.valueMap[field.nome];
						switch (field["@class"].split('.').pop()) {
							case "CampoCheckbox":
								return (
									<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
										<h5>{titolo}</h5>
										<p style={overflowStyle}>{fieldData ? "Sì" : "No"}</p>
									</div>
								);
							case "CampoImporto":
								return (
									<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
										<h5>{titolo}</h5>
										<p><NumberFormat value={Number.parseFloat(fieldData?.replace(",", "."))} prefix="€ " displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," /></p>
									</div>
								);
							case "CampoCFIVA":
								if (!fieldData?.startsWith("{")) {
									return (
										<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
											<h5>{titolo}</h5>
											<p style={overflowStyle}>
												{fieldData}
											</p>
										</div>
									);
								}
								const fd = JSON.parse(fieldData ?? "{}");
								return (
									<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
										<h5>{titolo}</h5>
										<p style={overflowStyle}>
											{`${
												fd.codiceFiscale
													? fd.codiceFiscale
													: (fd.partitaIva ? fd.partitaIva : fd.cfIva)
											} - ${fd.denominazione ? fd.denominazione : `${fd.nome} ${fd.cognome}`}`}
										</p>
									</div>
								);
							case "CampoData":
								return (
									<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
										<h5>{titolo}</h5>
										<p>{moment(fieldData, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate().toLocaleDateString()}</p>
									</div>
								);
							case "CampoMarcaBollo":
								return (
									<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
										<h5>{field.label}</h5>
										<p style={overflowStyle}>
											{t('tributi.costo')}
											{new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(field.importo))}
										</p>
									</div>
								);


							default:
								return fieldData && (
									<div key={field.nome} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
										<h5>{titolo}</h5>
										<p style={overflowStyle}>{fieldData}</p>
									</div>
								);
						}
					})
				}
			</div>
		);
	}

	/**
	 * Ritorna la struttura di validazione per il form di questo plugin nel backoffice.
	 * @param tributo Le informazioni del tributo.
	 * @returns {*}
	 */
	backofficePaymentValidation(t, tributo) {
		return this.portalPaymentValidation(t, tributo);
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentForm({ config }) {
		const [configuration, setConfiguration] = useState(config);
		//useEffect(() => setConfiguration(config), []);

		const { formState, setValue, resetField } = useFormContext();

		return configuration?.campi?.sort((a, b) => a.indice - b.indice)?.map((field) => {
			const fieldType = field["@class"].split('.').pop();
			const inputHandler = BackofficeInputMap[fieldType];

			return (
				<Controller
					key={field.nome}
					name={field.nome}
					required={field.obbligatorio}
					render={({ field: { onChange, value } }) => (
						<RenderInputComponent
							onChange={onChange}
							value={value}
							resetField={resetField}
							configuration={configuration}
							setValue={setValue}
							setConfiguration={setConfiguration}
							inputHandler={inputHandler}
							field={field}
							error={formState.errors[field.nome]}
							scrollTo={field.nome in formState.errors}
						/>
					)}
				/>
			);
		});
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentFormEditor({ config, basePath, edit }) {
		const form = useFormContext();

		if (!edit) {
			form.setValue(`${basePath}.codicePlugin`, "CAMPI");
			form.setValue(`${basePath}.@class`, "it.ras.pagopa.shared.plugins.pagamenti.campi.CampiData");
		}

		return config?.campi?.sort((a, b) => a.indice - b.indice)?.map((field) => {
			const fieldType = field["@class"].split('.').pop();
			const Input = BackofficeInputMap[fieldType];
			return (
				<Controller
					key={field.nome}
					name={`${basePath}.valueMap.${field.nome}`}
					required={field.obbligatorio}
					render={({ field: { onChange, value } }) => (
						<Input
							onChange={onChange}
							value={value}
							{...field}
							edit={edit}
						/>
					)}
				/>
			);
		});
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	backofficePaymentSubmit({ formValues, pluginData }) {
		function replaceVars(data, vars) {
			const regex = new RegExp(`\\$\\{(${Object.keys(vars).join('|')})\\}`, 'g');
			return data?.replace(regex, (m, $1) => vars[$1] || m);
		}

		const templateVars = Object.fromEntries(Object.entries(formValues).map(([k, v]) =>
			([k, k === "debitore" ? (v.personaFisica ? v.cfIva : (v.partitaIva ?? v.codiceFiscale)) : v])
		));

		const finalData = Object.keys(formValues).reduce((fData, field) => {
			fData[field] = typeof formValues[field] === 'object' ? JSON.stringify(formValues[field]) : formValues[field];
			return fData;
		}, {});

		return {
			formValues: this.configuration.templateCausale ? {
				...formValues,
				causale: replaceVars(this.configuration.templateCausale, templateVars)
			} : formValues,
			pluginData: [...pluginData.filter((p) => p.codicePlugin !== "CAMPI"), {
				valueMap: finalData,
				codicePlugin: "CAMPI",
				"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.CampiData"
			}]
		};
	}

	/**
	 * Componente chiamato durante il rendering del form da backoffice per i filtri di ricerca.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @returns {JSX.Element}
	 */
	searchFormRender({ control, configuration, basePath = "" }) {
		return () => {
			const { setValue } = useFormContext();
			setValue(`${basePath}pluginData.0.@class`, "it.ras.pagopa.shared.plugins.pagamenti.campi.CampiData");
			return (
				<Grid container direction="row" flexWrap="wrap" columnGap={3} rowGap={3} alignItems="center">
					{
						configuration?.campi?.filter((c) => c.searchable && c["@class"].split('.').pop() !== "CampoCFIVA").sort((a, b) => a.indice - b.indice)?.map((field) => (
							<Controller key={field.nome} control={control} render={({ field: { onChange, value } }) => getFieldFromData(field, { onChange, value, campo: field })} name={`${basePath}pluginData.0.valueMap.${field.nome}`} />
						))
					}
				</Grid>
			);
		};
	}

	getColumnsBackoffice(configuration) {
		return configuration?.campi?.filter((c) => c.searchable).map((field) => ({
			key: `p_${field.nome}`,
			intKey: field.nome,
			name: `${field.label}*`,
			frozen: false,
			draggable: true,
			resizable: true,
			highlight: false,
			minWidth: 200,
			width: 200
		}));
	}

	/**
	 * Componente chiamato durante il rendering del form di
	 * configurazione del tributo (backoffice).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @param baseFormPath Nome base degli input da inserire nel form.
	 * @param setValue Setta manualmente un valore del form.
	 * @returns {JSX.Element}
	 */
	configurationForm({ path }) {
		return (
			<div>
				<Controller name={`${path}.abilitaCSV`} render={({ field: { onChange, value } }) =>
					<Switch checked={value ?? false} onChange={({ target }) => onChange(target.checked)} />
				} />
				Abilita il plugin nel caricamento CSV.
				<Typography sx={{ paddingLeft: "20px" }}>
					<b>Importante: </b>
					utilizzare Label e Nome Proprietà
					<u>univoci.</u>
				</Typography>
				<Controller name={`${path}.campi`} render={({ field: { onChange, value } }) =>
					<FieldManager value={value ?? []} campi={value} onChange={(v) => onChange(v)} />
				} />

				<Divider className="mt-4">Aggregatori</Divider>
				<Controller name={`${path}.campi`} render={({ field: { value: campi } }) => (
					<Controller name={`${path}.aggregatori`} render={({ field: { onChange, value } }) => (
						<AggregatoriManager value={value ?? []} campi={campi} onChange={(v) => onChange(v)} />
					)} />
				)} />


				<Divider className="mt-4">Causale</Divider>

				<Controller name={`${path}.templateCausale`} render={({ field: { onChange, value } }) =>
					<TextField value={value} onChange={({ target }) => onChange(target.value)} label="Template della causale" variant="standard" className="mt-3 w-100" />
				} />
			</div>
		);
	}
}
