/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Button } from "design-react-kit";
import { useFetch } from "../../../Hooks/useFetch";
import ListaCard from "./ListaCard";
import Paginazione from "./Paginazione";
import ListaCardSingola from "./ListaCardSingola";
import { ENTE, STRUTTURA, TRIBUTO } from "../../../Store/Ricerca";
import OnClickLink from "./OnClickLink";
import ListaAlberoStrutture from "../Strutture/ListaAlberoStrutture";
import { getRootStructures } from "../Strutture/deepMapping";
import SpinnyPage from "../../../Backoffice/Components/SpinnyPage";

const cards = {
	ente: (e, action, t) => <ListaCardSingola nome={e.intestatario?.denominazione ?? ''} onClick={() => action(ENTE, e)} testoLink={t('homePageCard.selezionaEnte')} key={`ente-${e.codiceEnte}`} />,
	tributo: (e, action, t) => <ListaCardSingola nome={e.denominazione} onClick={() => action(TRIBUTO, e)} testoLink={t('homePageCard.selezionaServizio')} key={`tributo-${e.codiceEnte}-${e.codiceTributo}`} />,
	struttura: (all, codiceTributo, e, action) => (
		<ListaCardSingola
			nome={e.nome}
			onClick={() => action(STRUTTURA, e)}
			key={`struttura-${e.cdrEnte}`}
			linkDisabled={all != null ? !all.find((x) => x.cdr === e.cdr) : false}
		>
			<div className="strutture-organizzative">
				<div className="strutture-organizzative" style={{ maxHeight: "200px", overflowY: "auto" }}>
					<ListaAlberoStrutture
						strutture={e.figli}
						codiceEnte={e.codiceEnte}
						codiceTributo={codiceTributo}
						struttureTributo={all != null ? all.map((x) => x.cdr) : null}
						onClick={action}
					/>
				</div>
			</div>
		</ListaCardSingola>
	)
};

function RisultatiRicercaPagamentoListaAlberoStrutture(props) {
	const {
		action,
		strutture
	} = props;

	const lista = strutture.map((e) => (
		<RisultatiRicercaPagamentoElementoAlberoStrutture action={action} struttura={e} key={`struttura-${e.cdrEnte}`} />
	));


	return (
		<>
			<ul>
				{lista}
			</ul>
		</>
	);
}

function RisultatiRicercaPagamentoElementoAlberoStrutture(props) {
	const {
		action,
		struttura
	} = props;

	return (
		<>
			<li>
				<OnClickLink onClick={() => action(STRUTTURA, struttura)}>{struttura.nome}</OnClickLink>
				{struttura.figli.length > 0 && (
					<RisultatiRicercaPagamentoListaAlberoStrutture
						strutture={struttura.figli}
						action={action}
					/>
				)}
			</li>
		</>
	);
}


export function RisultatiRicercaPagamentoRedirect(props) {
	const {
		selection
	} = props;
	const history = useHistory();
	const codiceTributo = selection.tributo.split('@')[0];

	let url = `/pagamento/${selection.ente}/${codiceTributo}`;
	if (selection.struttura) {
		const codiceStruttura = selection.struttura.split('///')[0];
		url += `/${codiceStruttura}`;
	}

	history.push(url);
}

function RisultatiRicercaPagamentoListaGenerica(props) {
	const {
		numRisultati,
		elementi,
		page,
		pageSize,
		setPage
	} = props;
	const { t } = useTranslation('translation');

	return (
		<section className="section section-inset-shadow pb-0 pt-2 pb-5">
			<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
				<ListaCard
					t={t}
					risultatiTotali={numRisultati}
					elementi={elementi}
				/>
				<Paginazione
					t={t}
					paginaCorrente={page}
					perPagina={pageSize}
					risultatiTotali={numRisultati}
					onPageChange={(p) => setPage(p)}
				/>
			</div>
		</section>
	);
}

function RisultatiRicercaPagamentoListaEnti(props) {
	const {
		selection,
		addToSelection
	} = props;
	const { t } = useTranslation('translation');

	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const codiceTributo = selection?.tributo?.split('@')[0] ?? null;
	const { data, status } = useFetch(`/ente/query-public`, null, 'POST', {
		pageSize,
		page: page - 1,
		tributo: { codiceTributo, searchHide: false }
	});
	const results = data?.data;
	const numRisultati = data?.totalResults ? data?.totalResults : 0;

	if (status === "fetching") return (<div style={{ minHeight: "100vh" }}><SpinnyPage /></div>);

	const elementi = results ? results.map((e) => cards.ente(e, addToSelection, t)) : [];

	return (
		<RisultatiRicercaPagamentoListaGenerica
			numRisultati={numRisultati}
			elementi={elementi}
			page={page}
			pageSize={pageSize}
			setPage={setPage}
		/>
	);
}

function RisultatiRicercaPagamentoListaTributi(props) {
	const {
		selection,
		addToSelection
	} = props;
	const { t } = useTranslation('translation');


	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const { data, status } = useFetch(`/tributo/query-public`, null, 'POST', {
		pageSize,
		page: page - 1,
		struttura: selection.struttura && { codiceStruttura: selection.struttura },
		ente: { codiceEnte: selection.ente }
	});
	const results = data?.data;
	const numRisultati = data?.totalResults ? data?.totalResults : 0;


	if (status === "fetching") return (<div style={{ minHeight: "100vh" }}><SpinnyPage /></div>);

	const elementi = results ? results.map((e) => cards.tributo(e, addToSelection, t)) : <></>;

	return (
		<RisultatiRicercaPagamentoListaGenerica
			numRisultati={numRisultati}
			elementi={elementi}
			page={page}
			pageSize={pageSize}
			setPage={setPage}
		/>
	);
}

function RisultatiRicercaPagamentoListaStruttureEnte(props) {
	const {
		selection,
		addToSelection
	} = props;
	const { t } = useTranslation('translation');


	const [page, setPage] = useState(1);
	const [skipStr, setSkipStr] = useState(false);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const { data: strutture, status } = useFetch(`/struttura/query-public`, null, 'POST', {
		pageSize,
		page: page - 1,
		ente: { codiceEnte: selection.ente },
		tributo: selection.tributo && { codiceTributo: selection.tributo },
		senzaTributi: false
	});
	const results = strutture?.data;
	const numRisultati = strutture?.totalResults ? strutture?.totalResults : 0;

	if (status === "fetching") return (<div style={{ minHeight: "100vh" }}><SpinnyPage /></div>);

	// if (data !== null && numRisultati === 0) {
	// 	// 0 strutture, saltiamo la schermata
	// 	return <RisultatiRicercaPagamentoRedirect selection={selection} />;
	// }

	const elementi = results ? results
		.map((e) => cards.struttura(null, null, e, addToSelection)) : <></>;

	if (skipStr) {
		return (
			<>
				<section className="section pt-2 pb-1">
					<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
						<Button color="primary" className="" onClick={() => { setSkipStr(false); }}>
							{t('homePageCard.buttonStrutture')}
						</Button>
					</div>
					<RisultatiRicercaPagamentoListaTributi
						selection={selection}
						addToSelection={addToSelection}
					/>
				</section>
			</>
		);
	}

	if (numRisultati === 0 && !selection.tributo) {
		return (
			<RisultatiRicercaPagamentoListaTributi
				selection={selection}
				addToSelection={addToSelection}
			/>
		);
	}

	return (
		<>
			<section className="section pt-2 pb-1">
				<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
					<Button color="primary" className="" onClick={() => { setSkipStr(true); }}>
						{t('homePageCard.buttonPagamenti')}
					</Button>
				</div>
			</section>
			<RisultatiRicercaPagamentoListaGenerica
				numRisultati={numRisultati}
				elementi={elementi}
				page={page}
				pageSize={pageSize}
				setPage={setPage}
			/>
		</>
	);
}

function RisultatiRicercaPagamentoListaStruttureTributo(props) {
	const {
		selection,
		addToSelection
	} = props;

	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const codiceTributo = selection?.tributo?.split('@')[0] ?? null;

	const { data } = useFetch(`/tributo/query-public`, null, 'POST', {
		pageSize: 1,
		page: 0,
		ente: {
			codiceEnte: selection.ente
		},
		codiceTributo
	});
	const servizio = data?.data[0] ?? null;
	const results = servizio?.struttureAmmesse ?? [];

	const numRisultati = results.length;
	const minIndex = pageSize * (page - 1);
	let maxIndex = pageSize * (page);
	if (maxIndex > numRisultati) {
		maxIndex = numRisultati;
	}

	if (data !== null && numRisultati === 0) {
		return <RisultatiRicercaPagamentoRedirect selection={selection} />;
	}
	if (numRisultati === 1) {
		const newSel = { ...selection, struttura: results[0].cdrEnte };
		return <RisultatiRicercaPagamentoRedirect selection={newSel} />;
	}
	if (servizio && !servizio.spontaneo) {
		return <RisultatiRicercaPagamentoRedirect selection={selection} />;
	}

	const elementi = results ? getRootStructures(results)
		.slice(minIndex, maxIndex)
		.map((e) => cards.struttura(results, codiceTributo, e, addToSelection)) : <></>;

	return (
		<RisultatiRicercaPagamentoListaGenerica
			numRisultati={numRisultati}
			elementi={elementi}
			page={page}
			pageSize={pageSize}
			setPage={setPage}
		/>
	);
}

export default function RisultatiRicercaPagamento(props) {
	const {
		selection,
		addToSelection
	} = props;

	if (selection.ente && selection.tributo && selection.struttura) {
		return <RisultatiRicercaPagamentoRedirect selection={selection} />;
	}

	if (selection.tributo && !selection.ente) {
		return (
			<RisultatiRicercaPagamentoListaEnti
				selection={selection}
				addToSelection={addToSelection}
			/>
		);
	}

	if (selection.ente) {
		if (selection.struttura) {
			return (
				<RisultatiRicercaPagamentoListaTributi
					selection={selection}
					addToSelection={addToSelection}
				/>
			);
		}
		if (selection.tributo) {
			return (
				<RisultatiRicercaPagamentoListaStruttureTributo
					selection={selection}
					addToSelection={addToSelection}
				/>
			);
		}
		return (
			<RisultatiRicercaPagamentoListaStruttureEnte
				selection={selection}
				addToSelection={addToSelection}
			/>
		);
	}

	return (
		<RisultatiRicercaPagamentoListaTributi
			selection={selection}
			addToSelection={addToSelection}
		/>
	);

	/*
	const complete = selection.ente && selection.tributo && selection.struttura;

	const filters = {};
	if (selection.ente) filters.ente = { codiceEnte: selection.ente };
	if (selection.tributo) filters.tributo = { codiceTributo: selection.tributo };
	if (selection.struttura) filters.struttura = { cdr: selection.struttura };

	let endpointA = null; // Dati preferenziali
	let endpointB = null; // Da mostrare in caso non ci siano elementi da A

	if (selection.ente) {
		if (selection.tributo) {
			endpointA = 'struttura';
		} else if (selection.struttura) {
			endpointA = 'tributo';
		} else {
			// Mancano sia strutture che tributi
			endpointA = 'struttura';
			endpointB = 'tributo';
		}
	} else {
		// Tributi, mostriamo ente
		endpointA = 'ente';
	}
	const endpoint = selection.ente ? 'tributo' : 'ente';
	*/
}
