/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'design-react-kit';
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Grid, Switch, useMediaQuery } from "@mui/material";
import InputConDescrizione from '../Common/InputConDescrizione';
import { useFetch } from "../../../Hooks/useFetch";
import PluginManager from "../../../Plugins/PluginManager";
import configuration from "../../../configuration";
import useToken from "../../../Hooks/useToken";
import handleErrors from "../../../Backoffice/Utils/HandleErrors";
import { paymentProcessValidation } from "../../../DataModels/DebitoSpontaneo";
import SpinnyPage from "../../../Backoffice/Components/SpinnyPage";
import DataViewer from "../Common/DataViewer";
import DownloadPaymentNotice from "./DownloadPaymentNotice";
import { addToCart } from "../../../Store/Cart";

export default function ModaleServizio(props) {
	const {
		isOpen,
		setIsOpen,
		viewOnly,
		paymentData,
		iuv
	} = props;

	const debito = useMemo(() => (paymentData?.dettagli ? {
		...((paymentData?.pendenza?.debito ?? paymentData) ?? {}),
		pendenze: [paymentData]
	} : (paymentData?.pendenza?.debito ?? paymentData) ?? {}), [paymentData]);

	const jwt = useToken();
	const history = useHistory();
	const { t } = useTranslation('translation');


	const isMobileS = useMediaQuery("(max-width: 320px)");
	const dispatch = useDispatch();

	const [payeeView, setPayeeView] = useState(true);
	const [cfView, setCfView] = useState(false);
	const [emailView, setEmailView] = useState(false);
	const [callStatus, setCallStatus] = useState('idle');

	const cfIva = ((d) => d?.zipCode || d?.codiceFiscale || d?.partitaIva)(debito?.anagraficaDebitore);

	const prePaymentForm = useForm({
		resolver: yupResolver(paymentProcessValidation),
		defaultValues: { email: "", cfIva: "" }
	});

	const { formState, setValue, reset, setError, watch, clearErrors } = prePaymentForm;

	let selectedRata = useWatch({ control: prePaymentForm.control, name: "selectedRata" });
	selectedRata = debito?.pendenze?.filter((p) => p.altRata === 0)?.length > 1 ? selectedRata : debito?.pendenze?.[0];

	useEffect(() => { setValue("debito", debito); }, [JSON.stringify(debito)]);
	useEffect(() => { setValue("selectedRata", debito?.pendenze?.filter((p) => p.altRata === 0)?.length > 1 ? null : debito?.pendenze?.[0]); }, [isOpen, JSON.stringify(debito)]);
	useEffect(() => { setValue("email", debito?.anagraficaDebitore?.email); }, [JSON.stringify(debito)]);
	useEffect(() => { setValue("cfIva", debito?.anagraficaDebitore?.zipCode ? "STRANIERO" : cfIva); }, [debito?.anagraficaDebitore?.zipCode ? "STRANIERO" : cfIva]);
	useEffect(() => { setValue("pendenze", [
		{
			iuv: selectedRata?.iuv,
			codiceFiscaleEnte: debito?.ente?.intestatario?.codiceFiscale,
			pluginData: debito?.pluginData
		}
	]); }, [JSON.stringify(selectedRata)]);

	const pluginManager = new PluginManager();
	const plugins = debito?.tributo?.plugins?.map((conf) => pluginManager.getPlugin(conf.codicePlugin, conf)) ?? [];
	const hasPluginCampi = plugins.filter((plugin) => plugin.configuration.codicePlugin === "CAMPI").length > 0;


	useEffect(() => {
		clearErrors();
		setPayeeView(true);
		setCfView(false);
		setEmailView(false);
		setCallStatus('idle');
	}, [JSON.stringify(debito)]);

	function handleYes() {
		setCfView(true);
		setValue("cfIva", "");
		setEmailView(true);
		setValue("email", "");
		setPayeeView(false);
	}

	function handleNo() {
		setCfView(!cfIva);
		setEmailView(!debito?.anagraficaDebitore?.email);
		setPayeeView(false);
	}

	const pendenza = selectedRata;

	const multibeneficiario = pendenza?.dettagli?.some((d) => d.beneficiario != null);

	const model1Submit = async (data) => {
		setCallStatus('fetching');
		const response = await fetch(`${configuration.serverAddress}/pagamento/checkout`, {
			method: "POST",
			redirect: 'manual',
			body: JSON.stringify({ ...data, pendenze: data.pendenze.map((p) => ({ ...p, pluginData: p.pluginData.filter((plug) => plug).slice(0, 2) })) }),
			headers: new Headers({
				Authorization: jwt ? `Bearer ${jwt}` : undefined,
				...configuration.defaultHeaders
			})
		});

		if (response.status === 200) {
			const resp = await response.json();
			setCallStatus('fetched');
			window.location.href = resp.url;
		}

		if (response.status >= 400) {
			const error = await response.json();
			if (error.dettaglio !== undefined) {
				error.dettaglio.forEach((errore) => setError(errore.field.split('.').pop(), {
					description: errore.description }));
			} else handleErrors(error, setError);
		}
	};

	const downloadReceipt = async () => {
		const receiptUrl = jwt
			? `${configuration.serverAddress}/pagamento/receipt/${debito.ente.codiceEnte}/${paymentData?.pendenza?.iuv ?? selectedRata.iuv}`
			: `${configuration.serverAddress}/pagamento/receipt/${paymentData?.id ?? paymentData.idPayment}/${debito.ente.codiceEnte}/${paymentData?.pendenza?.iuv ?? selectedRata.iuv}`;

		const response = await fetch(receiptUrl, {
			method: 'GET',
			headers: new Headers({ Authorization: `Bearer ${jwt}` })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	const tableHead = [t('modaleServizio.rata'), t('modaleServizio.importo'), t('modaleServizio.stato'), t('modaleServizio.scadenza'), t('modaleServizio.causale'), ""];

	const getStatoPagamento = (d) => {
		if (!d) return ["", ""];
		if (d.pagata) return [t('modaleServizio.pagata'), "green"];
		if (d.numeroRata === 0 && debito?.pendenze?.filter((p) => p.numeroRata > 0)?.some((p) => p.pagata)) return [t('modaleServizio.pagRate'), "gray"];
		if (d.numeroRata === 0 && debito?.pendenze?.filter((p) => p.numeroRata > 0)?.some((p) => p.inCorso)) return [t('modaleServizio.pagRateCorso'), "#004573"];
		if (d.numeroRata > 0 && debito?.pendenze?.[0].pagata) return [t('modaleServizio.pagSolUnica'), "green"];
		if (d.inCorso) return [t('modaleServizio.inCorso'), "#004573"];
		if (moment(d.fineValidita).isBefore()) return [t('modaleServizio.nonPagabile'), "gray"];
		if (moment(d.inizioValidita).isAfter()) return [t('modaleServizio.nonAncoraPagabile'), "gray"];
		return [t('modaleServizio.daPagare'), "gray"];
	};

	const statoPagamento = (d) => {
		const stato = getStatoPagamento(d);
		return (
			<span className="stato-pagamento" style={{ background: stato[1] }}>
				{stato[0]}
			</span>
		);
	};

	const tableData = debito?.pendenze?.filter((r) => r.altRata === 0)?.length > 0
		? debito?.pendenze?.filter((r) => r.altRata === 0)?.map((c) => (
			{
				rata: [c.numeroRata === 0 ? "Soluzione unica" : c.numeroRata, "normal"],
				importo: [<NumberFormat value={c.dettagli.map((d) => d.importo).reduce((a, acc) => a + acc, 0.0)} prefix="€ " displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." />, "normal"],
				stato: [statoPagamento(c), "normal"],
				dataDiScadenza: [moment(c.scadenza).format("DD/MM/yyyy"), "normal"],
				causale: [c.causale, "large"]
			}
		)) : [];

	const tableButtons = debito?.pendenze?.filter((p) => p.altRata === 0)?.length > 0
		? debito?.pendenze?.filter((p) => p.altRata === 0)?.map((c, i) => (
			{
				button: {
					text: t('modaleServizio.visualizza'),
					function: () => prePaymentForm.setValue("selectedRata", c)
				}
			})) : [];

	if (isOpen && !debito) return <SpinnyPage />;

	return (
		<FormProvider {...prePaymentForm}>
			<Modal className="modale-checkout" isOpen={isOpen} toggle={() => setIsOpen(false)} labelledBy="riepilogo-carrello">
				<form onSubmit={prePaymentForm.handleSubmit(model1Submit)}>
					<ModalHeader toggle={() => setIsOpen(false)} id="riepilogo-carrello">
						{t('carrello.dettaglioPag')}
					</ModalHeader>
					<ModalBody className="py-4">
						{!selectedRata && <DataViewer head={tableHead} data={tableData} buttons={tableButtons} totale={0} />}
						{
							selectedRata && (
								<>
									{!hasPluginCampi && (
										<div className="dettaglio-pagamento row">
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.campoPagamentoEnte')}</h5>
												<p>{debito?.ente.intestatario.denominazione}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.tipologiaPagamento')}</h5>
												<p>{debito?.denominazioneTributo ?? debito?.tributo?.denominazione}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.causale')}</h5>
												<p>{debito?.causale}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.campoPagamentoData')}</h5>
												<p>{moment(debito?.pendenze[0].scadenza).format("DD/MM/yyyy")}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.codFisPIva')}</h5>
												<p>{cfIva}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.denominazione')}</h5>
												<p>
													{
														debito?.anagraficaDebitore?.nome?.concat(" ", debito?.anagraficaDebitore.cognome)
														?? debito?.anagraficaDebitore?.ragioneSociale
													}
												</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.Comune')}</h5>
												<p>{debito?.anagraficaDebitore?.comune}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.indirizzo')}</h5>
												<p>{debito?.anagraficaDebitore?.indirizzo}</p>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
												<h5>{t('carrello.note')}</h5>
												<p>
													{(debito?.note?.substr(0, 50) ?? "") + (debito?.note?.length > 50 ? "..." : "")}
												</p>
											</div>
										</div>
									)}
									{
										plugins?.filter((p) => p.configuration.codicePlugin !== "INTERESSI")?.map((plugin, i) =>
										{
											let index = debito?.pluginData?.findIndex((pv) => pv.codicePlugin === plugin.configuration.codicePlugin);
											if (index < 0) index = debito?.pluginData?.length ?? 0;
											return (
												<plugin.paymentSummary
													t={t}
													key={plugin.configuration.codicePlugin}
													payeeView={payeeView}
													debit={debito}
													config={plugin.configuration}
													path={`pendenze.0.pluginData.${index}`}
													viewOnly={viewOnly || getStatoPagamento(selectedRata)[0] !== t('modaleServizio.daPagare')}
													data={debito?.pluginData?.[index]}
												/>
											);
										})}
								</>
							)
						}
					</ModalBody>
					{(payeeView && !viewOnly && selectedRata && getStatoPagamento(selectedRata)[0] === t('modaleServizio.daPagare')) && (
						<ModalFooter>
							<div className="totale-pagamento">
								{t('modaleServizio.textPersonaDiversa')}
							</div>
							<div>
								<Button color="outline-primary" className="mr-4" onClick={() => handleYes()}>
									{t('modaleServizio.buttonSi')}
								</Button>
								<Button color="primary" onClick={() => handleNo()}>
									NO
								</Button>
							</div>
						</ModalFooter>
					)}
					<ModalBody>
						{!viewOnly && getStatoPagamento(selectedRata)[0] === t('modaleServizio.daPagare') && (
							<>
								{(cfView || emailView) && (
									<div>
										<h5 style={{ fontWeight: "bold" }}>{t('modaleServizio.infoPersonaDiversaPag')}</h5>
									</div>
								)}
								<div className="row mt-4">
									{cfView && (
										<div className="col-12 col-md-6 col-lg-4 col-xl-3">
											<Controller
												name="cfIva"
												defaultValue={debito?.anagraficaDebitore?.zipCode ? "STRANIERO" : cfIva}
												render={({ field: { onChange, value } }) => (
													<InputConDescrizione
														titolo={t('modaleServizio.codFisc')}
														obbligatorio
														tipo="text"
														nome="cfIva"
														placeholder={t('modaleServizio.placeholderCodFisc')}
														onChange={(e) => {
															const start = e.target.selectionStart;
															const end = e.target.selectionEnd;
															e.target.value = e.target.value.toUpperCase();
															e.target.setSelectionRange(start, end);
															onChange(e.target.value.toUpperCase());
														}}
														value={value ?? ""}
														errore={formState.errors.cfIva?.message}
														readonly={value === "STRANIERO"}
													/>
												)}
											/>
										</div>
									)}
									{emailView && (
										<div className="col-12 col-md-6 col-lg-4 col-xxl-3">
											<Controller
												name="email"
												render={({ field: { onChange, value } }) => (
													<InputConDescrizione
														titolo={t('modaleServizio.indirizzoEmail')}
														obbligatorio
														tipo="email"
														nome="email"
														placeholder={t('modaleServizio.placeholderEmail')}
														onChange={(e) => onChange(e.target.value)}
														value={value ?? ""}
														errore={formState.errors.email?.message}
													/>
												)}
											/>
										</div>
									)}
								</div>
								{cfView && (
									<div className="row mt-2" style={{ alignItems: "center" }}>
										<Controller
											name="cittadinoStraniero"
											render={({ field: { onChange, value } }) => (
												<>
													<Switch
														onChange={(v) => {
															onChange(v.target.checked);
															setValue("cfIva", v.target.checked ? "STRANIERO" : "");
														}}
														defaultChecked={false}
													/>
													<b>Non sono un cittadino italiano (I am not an Italian citizen)</b>
												</>
											)}
										/>
									</div>
								)}
								{(cfView || emailView) && (
									<div className="row mt-4">
										<div className="col-12">
											{t('modaleServizio.campiRichiesti')}
										</div>
									</div>
								)}
							</>
						)}

						{
							formState.errors.general && (
								<div className="alert alert-danger mt-2" role="alert">
									<b>{`${formState.errors.general.title}: `}</b>
									{formState.errors.general.description}
								</div>
							)
						}
						{
							!viewOnly && getStatoPagamento(pendenza)[0] === t('modaleServizio.daPagare') && (
								<div className="row mt-4 box-sportello">
									<div className="col-12 col-md-6">
										<h5>{t('modaleServizio.titoloInfoSportello')}</h5>
										<div className="box-sportello-istruzioni">
											<p>
												{t('modaleServizio.infoUtente')}
											</p>
											<ul>
												<li>
													{t('modaleServizio.infoUtente1')}
												</li>
												<li>{t('modaleServizio.infoUtente2')}</li>
											</ul>
										</div>
									</div>
									<div className="col-12 col-md-6 box-sportello-elenco">
										<p style={{ fontSize: "19px" }}>
											{t('modaleServizio.infoUtentePsp')}<br />
											<a href="https://www.pagopa.gov.it/it/dove-pagare" target="_blank" rel="noreferrer">https://www.pagopa.gov.it/it/dove-pagare</a><br />
											{t('modaleServizio.infoUtentePsp2')}
										</p>
									</div>
								</div>
							)
						}
					</ModalBody>
					<Grid component={ModalFooter} container direction="row" flexWrap="wrap" rowGap={5}>
						{selectedRata && (
							<div className="totale-pagamento">
								{t('carrello.importo')}
								<span className="totale-pagamento-importo">
									<NumberFormat
										value={paymentData?.importo ?? prePaymentForm.watch("selectedRata.dettagli")?.map((d) => d.importo).reduce((a, acc) => a + acc, 0.0)}
										prefix="€ " displayType="text" decimalScale={2} decimalSeparator=","
										thousandSeparator="." />
								</span>
							</div>
						)}

						{!viewOnly && getStatoPagamento(pendenza)[0] === t('modaleServizio.daPagare') && (
							<Grid container width="auto" direction="row" flexWrap="wrap" rowGap={2} columnGap={2}>
								{plugins.map((plugin) => {
									let index = debito?.pluginData?.findIndex((pv) => pv.codicePlugin === plugin.configuration.codicePlugin);
									if (index < 0) index = debito?.pluginData?.length ?? 0;
									const pluginData = debito?.pluginData[index];
									return <plugin.paymentSummaryButtons key={plugin.configuration.codicePlugin} debit={debito} payeeView={payeeView} config={plugin.configuration} path={`pendenze.0.pluginData.${index}`} viewOnly={viewOnly} data={pluginData} />;
								})}
								<DownloadPaymentNotice all disabled={payeeView} iuv={pendenza?.iuv} codiceEnte={debito.ente?.codiceEnte} pluginData={prePaymentForm.watch("pendenze.0.pluginData")} />
								{isMobileS ? <br /> : <></>}
								{!multibeneficiario && (
									<Button color="primary" onClick={() => { dispatch(addToCart({ ...pendenza, debito, tributo: debito.tributo, anagraficaDebitore: debito.anagraficaDebitore, ente: debito.ente, pluginData: watch("pendenze.0.pluginData") })); history.push("/area-personale/carrello"); }} disabled={payeeView || callStatus !== 'idle'} style={isMobileS ? { width: "100%" } : {}}>
										{t('modaleServizio.aggiungiCar')}
									</Button>
								)}
								<Button color="primary" type="submit" disabled={payeeView || callStatus !== 'idle'} style={isMobileS ? { width: "100%" } : {}}>
									{callStatus !== 'fetching' ? t('modaleServizio.pagaOn') : t('modaleServizio.carica')}
								</Button>
							</Grid>
						)}
						{viewOnly && (
							<div>
								{isMobileS ? <br /> : <></>}
								{selectedRata?.pagata && (
									<Button color="primary" className="mr-4" onClick={downloadReceipt} style={isMobileS ? { width: "100%" } : {}}>
										{t('modaleServizio.scaricaRicevuta')}
									</Button>
								)}
								<Button color="outline-primary" onClick={() => setIsOpen(!isOpen)} style={isMobileS ? { width: "100%" } : {}}>
									{t('carrello.bottoneModal')}
								</Button>
							</div>
						)}
					</Grid>
				</form>
			</Modal>
		</FormProvider>
	);
}
