import { useEffect } from "react";
import moment from "moment";

function evaluateCondition(context, condition, v) {
	let currentValue = v;
	if (context.field && context.field["@class"].split('.').pop() === "CampoCFIVA") {
		currentValue = JSON.stringify({
			personaFisica: currentValue.personaFisica
		});
	}

	switch (condition["@class"].split('.').pop()) {
		case "ConnettoreLogico":
			switch (condition.connettore) {
				case "And":
					return evaluateCondition(context, condition.condizione1, currentValue)
						&& evaluateCondition(context, condition.condizione2, currentValue);
				case "Or":
					return evaluateCondition(context, condition.condizione1, currentValue)
						|| evaluateCondition(context, condition.condizione2, currentValue);
				default:
					return false;
			}
		case "Compare":
			switch (condition.operatore) {
				case "Minore":
					return currentValue < condition.valore;
				case "Maggiore":
					return currentValue > condition.valore;
				case "MinoreUguale":
					return currentValue <= condition.valore;
				case "MaggioreUguale":
					return currentValue >= condition.valore;
				case "Uguale":
					return String(currentValue) === String(condition.valore);
				case "Diverso":
					return String(currentValue) !== String(condition.valore);
				default:
					return false;
			}
		case "TimeCondition":
			switch (condition.operatore) {
				case "Minore":
					return moment().isBefore(moment(condition.valore.replace("yyyy", moment().year().toString())));
				case "Maggiore":
					return moment().isAfter(moment(condition.valore.replace("yyyy", moment().year().toString())));
				case "MinoreUguale":
					return moment().isSameOrBefore(moment(condition.valore.replace("yyyy", moment().year().toString())));
				case "MaggioreUguale":
					return moment().isSameOrAfter(moment(condition.valore.replace("yyyy", moment().year().toString())));
				case "Uguale":
					return moment().isSame(moment(condition.valore.replace("yyyy", moment().year().toString())));
				case "Diverso":
					return !moment().isSame(moment(condition.valore.replace("yyyy", moment().year().toString())));
				default:
					return false;
			}
		default:
			return false;
	}
}

function runAction(context, action) {
	switch (action["@class"].split('.').pop()) {
		case "AzioneSetValore":
			if (action.valore === null) context.resetField(action.nomeCampoTarget);
			else context.setValue(action.nomeCampoTarget, action.valore);
			break;
		case "AzioneSetVisible":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, visible: action.valore }))
			}));
			break;
		case "AzioneSetValoreMin":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, min: action.valore }))
			}));
			break;
		case "AzioneSetReadonly":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, readonly: action.valore }))
			}));
			break;
		case "AzioneSetLabel":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, label: action.valore }))
			}));
			break;
		case "AzioneSetCE":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, capitoloEntrata: action.valore }))
			}));
			break;
		case "AzioneSetRequired":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, obbligatorio: action.valore }))
			}));
			break;
		case "AzioneSetSuggestion":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, suggerimento: action.valore }))
			}));
			break;
		case "AzioneHideSelectOptions":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, valori: field.valori.map((val, i) => ({ ...val, hidden: action.valore[i] })) }))
			}));
			break;
		case "AzioneSetTipoDebitore":
			context.setConfiguration((conf) => ({
				...conf,
				campi: conf.campi.map((field) => (field.nome !== action.nomeCampoTarget ? field : { ...field, tipo: action.valore }))
			}));
			break;
		default:
	}
}

function executeRule(context, rule, currentValue) {
	switch (rule["@class"].split('.').pop()) {
		case "RegolaIf":
			if (evaluateCondition(context, rule.condizione, currentValue)) {
				rule.azioni.forEach((action) => runAction(context, action));
			}
			break;
		default:
	}
}

export function applyFieldRules(context, rules, currentValue) {
	rules?.forEach((rule) => executeRule(context, rule, currentValue));
}

export default function RenderInputComponent({ onChange, value, control, setValue, setConfiguration, configuration, inputHandler, field, error, scrollTo, resetField }) {
	useEffect(() => {
		applyFieldRules({
			resetField,
			control,
			setValue,
			setConfiguration,
			configuration,
			field
		}, field.regole, value);
	}, [value]);

	return inputHandler({ onChange, value, error, scrollTo, key: field.key, ...field });
}
