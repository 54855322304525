/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import HeaderProfilo from './HeaderProfilo';
import ProfilobottoneCard from './ProfilobottoneCard';
import { useFetch } from "../../../Hooks/useFetch";
import DataViewer from "../Common/DataViewer";
import ModaleServizio from "../CarrelloPagamento/ModaleServizio";
import InputConDescrizione from "../Common/InputConDescrizione";
import SpinnyPage from "../../../Backoffice/Components/SpinnyPage";

export default function Profilo() {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [isOpenServPag, setIsOpenServPag] = useState(null);

	const isMobileLarge = useMediaQuery("(max-width: 425px)");
	const isLaptop = useMediaQuery("(max-width: 991px)");
	const { t } = useTranslation('translation');


	const { data: dataDebiti, status: dataDebitiStatus } = useFetch('/debiti/user', null, 'POST', {
		pageSize: 5,
		page: 0,
		spontaneo: false,
		orderBy: [
			{ field: "pendenze.scadenza", desc: false }
		],
		pendenze: [{ pagata: false, escludiSolUnicaRate: true }]
	});

	const { data: dataPagamenti, status: dataPagamentiStatus } = useFetch('/pagamento/query/public', null, 'POST', {
		pageSize: 5,
		page: 0
	});

	const { data: accountInfo } = useFetch('/user/info', null);

	const getStatoPagamento = (d) => {
		if (d.pagato) return [t('profilo.pagato'), "green"];
		if (d.pendenze.some((p) => p.inCorso)) return ["In corso", "#004573"];
		if (d.pendenze.filter((p) => !p.pagata).some((p) => moment(p.fineValidita).isBefore())) return [t('profilo.nonPiuPagabile'), "gray"];
		if (d.pendenze.filter((p) => !p.pagata).some((p) => moment(p.inizioValidita).isAfter())) return [t('profilo.nonAncoraPagabile'), "gray"];
		if (d.pendenze.some((p) => p.pagata)) return [t('profilo.parzialmentePagato'), "gray"];
		return [t('profilo.daPagare'), "gray"];
	};

	const statoDebito = (d) => {
		const [testo, colore] = getStatoPagamento(d);
		return (
			<span className="stato-pagamento" style={{ background: colore }}>
				{testo}
			</span>
		);
	};

	const debitiHeader = [t('profilo.scadenza'), t('profilo.causale'), t('profilo.importo'), t('profilo.stato'), ""];
	const debitiData = dataDebiti?.data.length > 0 ? dataDebiti.data.map((c) => (
		{
			scadenza: [moment(c.pendenze[0].scadenza).format("DD/MM/yyyy"), "normal"],
			causale: [c.pendenze[0].causale, "large"],
			importo: [`${c.pendenze[0].dettagli.reduce((acc, v) => v.importo + acc, 0)} €`, "normal"],
			stato: [statoDebito(c), "normal"]
		}
	)) : [];
	const debitiButtons = dataDebiti?.data.length > 0 ? dataDebiti.data.map((c) => (
		{
			button: (c.pendenze[0].pagata
				|| c.pendenze[0].inCorso
				|| moment(c.pendenze[0].fineValidita).isBefore()
				|| moment(c.pendenze[0].inizioValidita).isAfter())
				? {
					text: t('profilo.visualizza'),
					function: () => setIsOpenServPag(c)
				}
				: {
					text: t('profilo.paga'),
					function: () => setIsOpenServ(c)
				}
		})) : [];

	const pagamentiHeader = [t('profilo.data'), t('profilo.causale'), t('profilo.codice'), t('profilo.importo'), ""];
	const pagamentiData = dataPagamenti?.data.length > 0 ? dataPagamenti.data.map((c) => (
		{
			data: [moment(c?.transazione?.dataPagamento).format("DD/MM/yyyy"), "normal"],
			causale: [c?.pendenza?.debito?.causale, "large"],
			codice: [c?.transazione?.ccp, "normal"],
			importo: [`${c.pendenza?.dettagli?.reduce((acc, v) => v.importo + acc, 0)} €`, "normal"]
		}
	)) : [];
	const pagamentiButtons = dataPagamenti?.data.length > 0 ? dataPagamenti.data.map((c) => (
		{
			button: {
				text: t('profilo.visualizza'),
				function: () => setIsOpenServPag({
					...c?.pendenza?.debito,
					pendenze: [c?.pendenza],
					idPayment: c?.id,
					id: c?.id
				})
			}
		}
	)) : [];

	return (
		<>
			<HeaderProfilo menuAttivo="profilo" />
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				<div className="container" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
					<label
						className="input-con-descrizione no-bot-margin"
						style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}
					>
						<h5 style={{ width: "100%", paddingRight: "10px" }}>{t('profilo.ultimoAccesso')}</h5>
						<input
							type="text"
							name="accessi"
							readOnly
							value={accountInfo?.ultimoAccesso ? moment(accountInfo?.ultimoAccesso).format("DD/MM/yyyy HH:mm:ss") : "Primo accesso"}
							style={{ textAlign: "right", backgroundColor: "transparent", width: 200 }}
						/>
					</label>
				</div>
				<div className="container">
					<div className="row pt-2 pl-4 pr-4" style={{ justifyContent: isMobileLarge ? "center" : "left" }}>
						<h3>{t('profilo.titoloPag')}</h3>
					</div>
				</div>
				<div className="container bg-white">
					<div className="row mt-1 bg-white pt-4 pl-4 pr-4 pb-4 carrello-elemento-row">
						{!dataDebiti || dataDebitiStatus === "fetching"
							? <div className="pageLoader" style={{ height: "50px", width: "50px" }} />
							: <DataViewer head={debitiHeader} data={debitiData} buttons={debitiButtons} />}
					</div>
				</div>
				<div className="p-1 text-right container">
					<div className="carrello-elemento-row text-right">
						<Link to="/area-personale/posizione-debitoria">{t('profilo.visualizzaDebiti')}</Link>
					</div>
				</div>
			</section>
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				<div className="container">
					<div className="row pt-4 pl-4 pr-4" style={{ textAlign: isMobileLarge ? "center" : "left" }}>
						<h3>{t('profilo.titoloUltimiPag')}</h3>
					</div>
				</div>
				<div className="container bg-white">
					<div className="row mt-1 bg-white pt-4 pl-4 pr-4 pb-4 carrello-elemento-row">
						{!dataPagamenti || dataPagamentiStatus === "fetching"
							? <div className="pageLoader" style={{ height: "50px", width: "50px" }} />
							: <DataViewer head={pagamentiHeader} data={pagamentiData} buttons={pagamentiButtons} />}
					</div>
				</div>
				<div className="p-1 text-right container">
					<Link to="/area-personale/storico-pagamenti">{t('profilo.visualizzaPag')}</Link>
				</div>
			</section>
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				<div className="container">
					{
						!isLaptop
							? (
								<>
									<div className="row small-row pb-2 pr-4 pl-4 pt-4">
										<div className="col-3">
											<Link to="/enti">
												<ProfilobottoneCard
													tipo={t('profilo.pagamenti')}
													icona="fas fa-euro-sign"
													titolo={t('profilo.listaPag')}
												/>
											</Link>
										</div>
										<div className="col-3">
											<Link to="/area-personale/posizione-debitoria">
												<ProfilobottoneCard
													tipo={t('profilo.pagamenti')}
													icona="fas fa-euro-sign"
													titolo={t('profilo.posizioneDeb')}
												/>
											</Link>
										</div>
										<div className="col-3">
											<Link to="/area-personale/storico-pagamenti">
												<ProfilobottoneCard
													tipo={t('profilo.pagamenti')}
													icona="fas fa-euro-sign"
													titolo={t('profilo.storicoPag')}
												/>
											</Link>
										</div>
									</div>
									<div className="row small-row pb-4 pr-4 pl-4 pt-2">
										<div className="col-3">
											<Link to="/autenticazione/cambiaRuolo">
												<ProfilobottoneCard
													tipo={t('profilo.ruolo')}
													icona="fas fa-key"
													titolo={t('profilo.cambiaRuolo')}
												/>
											</Link>
										</div>
										<div className="col-3">
											<Link to="/area-personale/dati-anagrafici">
												<ProfilobottoneCard
													tipo={t('profilo.datiAna')}
													icona="far fa-address-book"
													titolo={t('profilo.titoloDatiAna')}
												/>
											</Link>
										</div>
										<div className="col-3">
											<Link to="/area-personale/carrello">
												<ProfilobottoneCard
													tipo={t('profilo.carrello')}
													icona="fas fa-shopping-cart"
													titolo={t('profilo.titoloCar')}
												/>
											</Link>
										</div>
									</div>
								</>
							)
							: (
								<>
									<div className="row small-row pb-2 pr-4 pl-4 pt-4">
										<div className="col-lg-4 col-md-6 profilo-bottone-card-div">
											<Link to="/enti">
												<ProfilobottoneCard
													tipo={t('profilo.pagamenti')}
													icona="fas fa-euro-sign"
													titolo={t('profilo.listaPag')}
												/>
											</Link>
										</div>
										<div className="col-lg-4 col-md-6">
											<Link to="/area-personale/posizione-debitoria">
												<ProfilobottoneCard
													tipo={t('profilo.pagamenti')}
													icona="fas fa-euro-sign"
													titolo={t('profilo.posizioneDeb')}
												/>
											</Link>
										</div>
									</div>
									<div className="row small-row pb-2 pr-4 pl-4 pt-2">
										<div className="col-lg-4 col-md-6 profilo-bottone-card-div">
											<Link to="/area-personale/storico-pagamenti">
												<ProfilobottoneCard
													tipo={t('profilo.pagamenti')}
													icona="fas fa-euro-sign"
													titolo={t('profilo.storicoPag')}
												/>
											</Link>
										</div>
										<div className="col-lg-4 col-md-6">
											<Link to="/autenticazione/cambiaRuolo">
												<ProfilobottoneCard
													tipo={t('profilo.ruolo')}
													icona="fas fa-key"
													titolo={t('profilo.cambiaRuolo')}
												/>
											</Link>
										</div>
									</div>
									<div className="row small-row pb-2 pr-4 pl-4 pt-2">
										<div className="col-lg-4 col-md-6 profilo-bottone-card-div">
											<Link to="/area-personale/dati-anagrafici">
												<ProfilobottoneCard
													tipo={t('profilo.datiAna')}
													icona="far fa-address-book"
													titolo={t('profilo.titoloDatiAna')}
												/>
											</Link>
										</div>
										<div className="col-lg-4 col-md-6">
											<Link to="/area-personale/carrello">
												<ProfilobottoneCard
													tipo={t('profilo.carrello')}
													icona="fas fa-shopping-cart"
													titolo={t('profilo.titoloCar')}
												/>
											</Link>
										</div>
									</div>
								</>
							)
					}
				</div>
			</section>
			<ModaleServizio
				isOpen={isOpenServ != null}
				setIsOpen={() => { setIsOpenServ(null); }}
				paymentData={isOpenServ}
			/>
			<ModaleServizio
				isOpen={isOpenServPag != null}
				setIsOpen={() => { setIsOpenServPag(null); }}
				paymentData={isOpenServPag}
				viewOnly
			/>
		</>
	);
}
