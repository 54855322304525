import React from "react";
import { Divider, FormControlLabel, Switch, TextField } from "@mui/material";
import CampoSelectSetting from "./Types/CampoSelectSetting";
import CampoStringSetting from "./Types/CampoStringSetting";
import EditorRegole from "./Editor/EditorRegole";
import CampoCheckboxSetting from "./Types/CampoCheckboxSetting";
import CampoNumericoSetting from "./Types/CampoNumericoSetting";
import CampoImportoSetting from "./Types/CampoImportoSetting";
import CampoEmailSetting from "./Types/CampoEmailSetting";
import CampoCFIVASetting from "./Types/CampoCFIVASetting";
import CampoDataSetting from "./Types/CampoDataSetting";
import CampoSelect from "./Types/CampoSelect";
import CampoString from "./Types/CampoString";
import CampoCheckbox from "./Types/CampoCheckbox";
import CampoNumerico from "./Types/CampoNumerico";
import CampoImporto from "./Types/CampoImporto";
import CampoEmail from "./Types/CampoEmail";
import CampoCFIVA from "./Types/CampoCFIVA";
import CampoBelfiore from "./Types/CampoBelfiore";
import CampoBelfioreSetting from "./Types/CampoBelfioreSetting";
import CampoMarcaBollo from "./Types/CampoMarcaBollo";
import CampoMarcaBolloSetting from "./Types/CampoMarcaBolloSetting";

export function getFieldFromData(data, props) {
	return {
		CampoSelect: <CampoSelect {...props} />,
		CampoStringa: <CampoString {...props} />,
		CampoCheckbox: <CampoCheckbox {...props} />,
		CampoNumerico: <CampoNumerico {...props} />,
		CampoImporto: <CampoImporto {...props} />,
		CampoEmail: <CampoEmail {...props} />,
		CampoCFIVA: <CampoCFIVA {...props} />,
		CampoBelfiore: <CampoBelfiore {...props} />,
		CampoMarcaBollo: <CampoMarcaBollo {...props} />
	}[data["@class"].split('.').pop()];
}

export default function SingleFieldEditor({ value, fv, fChange, campi }) {
	return (
		<div>
			<TextField value={fv.label} onChange={({ target }) => fChange({ ...fv, label: target.value })} label="Label" variant="standard" style={{ width: "200px" }} />
			<TextField value={fv.nome} onChange={({ target }) => fChange({ ...fv, nome: target.value })} label="Nome proprietà" variant="standard" className="ml-3" style={{ width: "150px" }} />
			<TextField value={fv.suggerimento} onChange={({ target }) => fChange({ ...fv, suggerimento: target.value })} label="Suggerimento" variant="standard" className="ml-3" style={{ width: "375px" }} />
			<TextField value={fv.key} onChange={({ target }) => fChange({ ...fv, key: target.value })} label="key" variant="standard" className="ml-3" style={{ width: "150" }} />
			<Divider className="mt-5">Contenuto del campo</Divider>
			{
				{
					CampoSelect: <CampoSelectSetting value={fv} onChange={fChange} />,
					CampoStringa: <CampoStringSetting value={fv} onChange={fChange} />,
					CampoCheckbox: <CampoCheckboxSetting value={fv} onChange={fChange} />,
					CampoNumerico: <CampoNumericoSetting value={fv} onChange={fChange} />,
					CampoImporto: <CampoImportoSetting value={fv} onChange={fChange} />,
					CampoEmail: <CampoEmailSetting value={fv} onChange={fChange} />,
					CampoCFIVA: <CampoCFIVASetting value={fv} onChange={fChange} />,
					CampoData: <CampoDataSetting value={fv} onChange={fChange} />,
					CampoBelfiore: <CampoBelfioreSetting value={fv} onChange={fChange} />,
					CampoMarcaBollo: <CampoMarcaBolloSetting value={fv} onChange={fChange} />
				}[fv["@class"].split('.').pop()]
			}
			<Divider className="mt-5">Impostazioni del campo</Divider>
			<div className="d-flex flex-wrap mt-3">
				<FormControlLabel control={<Switch checked={fv.obbligatorio} onChange={({ target }) => fChange({ ...fv, obbligatorio: target.checked })} />} style={{ width: "32%" }} defaultValue={false} label="Obbligatorio" />
				<FormControlLabel control={<Switch checked={fv.readonly} onChange={({ target }) => fChange({ ...fv, readonly: target.checked })} />} style={{ width: "32%" }} defaultValue={false} label="Sola lettura" />
				<FormControlLabel control={<Switch checked={fv.searchable} onChange={({ target }) => fChange({ ...fv, searchable: target.checked })} />} style={{ width: "32%" }} defaultValue label="Ricercabile" />
				<FormControlLabel control={<Switch checked={fv.visible} onChange={({ target }) => fChange({ ...fv, visible: target.checked })} />} style={{ width: "32%" }} defaultValue label="Visibile nel form" />
				<FormControlLabel control={<Switch checked={fv.nascostoSpontaneo} onChange={({ target }) => fChange({ ...fv, nascostoSpontaneo: target.checked })} />} style={{ width: "32%" }} defaultValue label="Nascosto nel form spontaneo" />
				<FormControlLabel control={<Switch checked={fv.ricevuta} onChange={({ target }) => fChange({ ...fv, ricevuta: target.checked })} />} style={{ width: "32%" }} defaultValue label="Visibile nella ricevuta" />
				<FormControlLabel control={<Switch checked={fv.csv != null} onChange={({ target }) => fChange({ ...fv, csv: target.checked ? "" : null })} />} style={{ width: "32%" }} defaultValue label="Campo settabile da CSV" />
				<br />
				{fv.csv != null && <TextField value={fv.csv} defaultValue={null} fullWidth onChange={({ target }) => fChange({ ...fv, csv: target.value })} label="Nome del campo CSV" variant="standard" />}
			</div>

			<Divider className="mt-5">Editor delle regole</Divider>
			<EditorRegole
				value={fv.regole}
				campi={campi}
				currentField={value.find((c) => c.nome === fv.nome)}
				onChange={(v) => fChange({ ...fv, regole: v })}
			/>
		</div>
	);
}
