import { Box, Modal, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';


export default function ConfirmModal(
	{ open, title, text, elements, onConfirm, onDeny, loading, confirmText, denyText, width }
) {
	const { t } = useTranslation('translation');

	const isMobileM = useMediaQuery("(max-width: 375px");

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: isMobileM ? 300 : (width ?? 400),
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	return (
		<Modal
			open={open}
		>
			<Box sx={style}>
				<h3>{title}</h3>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					{text}
					{elements}
				</Typography>
				{/* eslint-disable-next-line */}
				<div align="center" className="mt-5 mb-1">
					<LoadingButton onClick={onConfirm} loading={loading} variant="contained" className="mr-2">{confirmText ?? t('carrello.continua')}</LoadingButton>
					{onDeny && <LoadingButton onClick={onDeny} variant="outlined" className="ml-2">{denyText ?? t('carrello.annulla')}</LoadingButton>}
				</div>
			</Box>
		</Modal>
	);
}
