import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationSARD from './locales/sard/translation.json';


const resources = {
	sard: {
		translation: translationSARD
	},
	fr: {
		translation: translationFR
	},
	es: {
		translation: translationES
	},
	en: {
		translation: translationEN
	},
	it: {
		translation: translationIT
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: localStorage.getItem('language'), //Imposto di default l'ultima lingua selezionata se faccio un refresh
		fallbackLng: 'it',
		returnObject: true,
		interpolation: {
			escapeValue: false
		}
	});


export default i18n;
