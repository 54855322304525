/* eslint-disable react/jsx-no-target-blank */
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';


import { useSelector } from "react-redux";
import Logo from './Logo';

/* eslint-disable react/jsx-one-expression-per-line */
export default function Footer() {
	const user = useSelector((state) => state.authentication.user);
	const currentYear = new Date().getFullYear();
	const { t } = useTranslation('translation');


	return (
		<footer className="it-footer pb-0 pt-3">
			<div className="it-footer-main">
				<div className="container" style={{ padding: "20px" }}>
					<div className="it-brand-wrapper" style={{ paddingTop: "0px", paddingBottom: "20px" }}>
						<Link to={user ? "/area-personale" : "/"}>
							<div className="it-brand-text">
								<Logo />
							</div>
						</Link>
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-4 col-sm-6 pb-2">
							<h6 className="border-white border-bottom font-weight-normal pb-2">
								<span>{t('footer.titolo1')}</span>
							</h6>
							<div>
								<p>
									<Trans i18nKey="footer.descrizione1" />
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 pb-2">
							<h6 className="border-white border-bottom font-weight-normal pb-2" style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
								<span>{t('footer.titolo2')}</span>
							</h6>
							<div>
								<Trans i18nKey="footer.descrizione2" />
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 pb-2">
							<h6 className="border-white border-bottom font-weight-normal pb-2" style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
								<span>{t('footer.titoloSupportoMultilingua')}</span>
							</h6>
							<div>
								<ul style={{ marginTop: "10px", listStyleType: "disc", paddingLeft: "20px" }}>
									<li>
										<Trans
											i18nKey="footer.altreLingue.sardo"
											components={{
												a: (
													<a
														href="https://www.regione.sardegna.it/regione/istituzione/struttura-organizzativa/assessorato-della-pubblica-istruzione-beni-culturali-informazione-spettacolo-e-sport-488/direzione-generale-dei-beni-culturali-informazione-spettacolo-e-sport-489/servizio-lingua-e-cultura-sarda-493"
														target="_blank"
														rel="noopener noreferrer"
														style={{ color: "#88bee4fc", textDecoration: "underline" }}
													>
														Servizio Lingua e Cultura Sarda
													</a>
												)
											}}
										/>
									</li>
									<li>{t('footer.altreLingue.inglese')}</li>
									<li>{t('footer.altreLingue.spagnolo')}</li>
									<li>{t('footer.altreLingue.francese')}</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row" style={{ justifyContent: "center", margin: "15px" }}>
						<div className="row align-content-center" style={{ gap: "20px", justifyContent: "center" }}>
							<div title="" style={{ height: "130px", width: "150px", paddingTop: "12px" }}>
								<img src="./images/logo-europa.png" alt="" />
							</div>
							<div title="" style={{ height: "130px", width: "150px" }}>
								<img src="./images/logo-repubblica-italiana.svg" alt="" />
							</div>
							<div title="" style={{ height: "130px", width: "200px" }}>
								<img src="https://www.regione.sardegna.it/immagini/61_240_20210401105330.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="it-footer-small-prints clearfix d-print-none">
				<div className="container">
					<h3 className="sr-only">Sezione Link Utili</h3>
					<ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
						<li className="list-inline-item"><a href="/documenti/Informativa_GDPR.pdf" title="Vai alla pagina: Privacy Policy" rel="noreferrer"><span>Privacy Policy</span></a></li>
						<li className="list-inline-item"><a href="/documenti/Cookie_Policy.pdf" title="Vai alla pagina: Cookie policy"><span>Cookie Policy</span></a></li>
						<li className="list-inline-item"><a target="_blank" download href="./manualistica/Manuale_Cittadino.pdf" title="Manuale utente" rel="noreferrer"><span>Manuale utente</span></a></li>
						<li className="list-inline-item flex-grow-1"><div>&copy;<a target="_blank" className=" d-print-none" href="https://www.regione.sardegna.it">&nbsp;</a>{`${currentYear} Regione Autonoma della Sardegna`}</div></li>
					</ul>
				</div>
			</div>
		</footer>
	);
}
