/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'design-react-kit';
import { useSelector } from "react-redux";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { useMediaQuery } from "@mui/material";
import { useFetch } from "../../../Hooks/useFetch";
import HeaderProfilo from './HeaderProfilo';
import ModaleServizio from '../CarrelloPagamento/ModaleServizio';
import Paginazione from "../Common/Paginazione";
import Select from "../Common/Select";
import DataViewer from "../Common/DataViewer";
import ConfirmModal from "../../../Backoffice/Components/ConfirmModal";
import configuration from "../../../configuration";
import useToken from "../../../Hooks/useToken";

export default function PosizioneDebitoriaSpontanea() {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [openFailDelete, setOpenFailDelete] = useState(false);
	const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
	const [debitToDelete, setDebitToDelete] = useState({});
	const [refresh, setRefresh] = useState(0);
	const [filters, setFilters] = useState(
		{
			tributo: null,
			pendenze: [
				{
					pagata: false,
					escludiSolUnicaRate: true
				}]
		}
	);
	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const { control, handleSubmit } = useForm();
	const jwt = useToken();

	const isTablet = useMediaQuery("(max-width: 768px)");
	const isLaptop = useMediaQuery("(max-width: 991px)");
	const { t } = useTranslation('translation');


	const { data, status } = useFetch('/debiti/user', null, 'POST', {
		pageSize,
		page: page - 1,
		...filters,
		spontaneo: true,
		stato: "Eseguito"
	}, refresh);

	const { data: paymentTypes } = useFetch('/debiti/user/filters', null, 'POST');

	const numRisultati = data?.totalResults;

	const deleteDebit = async () => {
		await fetch(`${configuration.serverAddress}/debiti/delete/spontaneo`, {
			method: 'DELETE',
			body: JSON.stringify(debitToDelete),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		}).then(async (response) => {
			setDebitToDelete({});
			setOpenConfirm(false);
			setRefresh(refresh + 1);
			if (response.status !== 200) setOpenFailDelete(true);
			else setOpenSuccessDelete(true);
		});
	};

	function buildDto(debit) {
		return {
			tributo: {
				ente: {
					codiceEnte: debit.ente.codiceEnte
				},
				codiceTributo: debit.tributo.codiceTributo
			},
			codiceDebito: debit.codiceDebito,
			anagraficaDebitore: debit.anagraficaDebitore
		};
	}

	const getStatoPagamento = (d) => {
		if (d.pagato) return [t('pagamentiSpontanei.pagato'), "green"];
		if (d.pendenze.some((p) => p.inCorso)) return ["In corso", "#004573"];
		if (d.pendenze.filter((p) => !p.pagata).some((p) => moment(p.fineValidita).isBefore())) return [t('pagamentiSpontanei.nonPagabile'), "gray"];
		if (d.pendenze.filter((p) => !p.pagata).some((p) => moment(p.inizioValidita).isAfter())) return [t('pagamentiSpontanei.nonAncoraPagabile'), "gray"];
		if (d.pendenze.some((p) => p.pagata)) return [t('pagamentiSpontanei.parzialmentePagato'), "gray"];
		return [t('pagamentiSpontanei.daPagare'), "gray"];
	};

	const statoPagamento = (d) => {
		const [testo, colore] = getStatoPagamento(d);
		return (
			<span className="stato-pagamento" style={{ background: colore }}>
				{testo}
			</span>
		);
	};

	const tableHead = [t('pagamentiSpontanei.scadenza'), t('pagamentiSpontanei.causale'), t('pagamentiSpontanei.importo'), t('pagamentiSpontanei.stato'), ""];

	const tableData = data?.data.length > 0 ? data.data.map((c) => (
		{
			scadenza: [moment(c.pendenze[0].scadenza).format("DD/MM/yyyy"), "normal"],
			causale: [c.causale, "large"],
			importo: [`${c.pendenze[0].dettagli.reduce((acc, v) => v.importo + acc, 0)} €`, "normal"],
			stato: [statoPagamento(c), "normal"]
		}
	)) : [];

	const tableButtons = data?.data.length > 0 ? data.data.map((c) => (
		{
			button1: (c.pagata
				|| c.pendenze[0].inCorso
				|| moment(c.fineValidita).isBefore()
				|| moment(c.inizioValidita).isAfter())
				? {}
				: {
					text: t('pagamentiSpontanei.buttonElimina'),
					function: () => {
						setOpenConfirm(true);
						setDebitToDelete(buildDto(c));
					}
				},
			button2: {
				text: t('pagamentiSpontanei.buttonVisualizza'),
				function: () => setIsOpenServ(c)
			}
		})) : [];

	const searchFunction = (d) => {
		const finalData = {
			tributo: d.tipo === "null" ? null : {
				ente: { codiceEnte: d.tipo.split('///')[0] },
				codiceTributo: d.tipo.split('///')[1]
			},
			pendenze: [
				{
					pagata: d.stato === "null" ? null : (d.stato === "Pagati"),
					escludiSolUnicaRate: true
				}
			]
		};
		setFilters(finalData);
	};

	return (
		<>
			<HeaderProfilo menuAttivo="posizione-spontanea" />
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				{
					!isTablet && (
						<div className="container p-0">
							<h3 className="ml-0">{t('pagamentiSpontanei.titoloPagSpontanei')}</h3>
						</div>
					)
				}
				<div className="container p-0 w-100">
					<div className="row align-items-center mr-0 ml-0">
						<div className="col-md-4">
							<Controller
								control={control}
								name="stato"
								defaultValue="NonPagati"
								render={({ field: { onChange, value } }) => (
									<div className="bootstrap-select-wrapper input-con-descrizione">
										<Select
											titolo={t('pagamentiSpontanei.stato')}
											valoreSelezionato={value}
											nome="stato"
											onChange={onChange}
											scelte={{
												null: t('pagamentiSpontanei.tuttiStati'),
												NonPagati: t('pagamentiSpontanei.nonPagati'),
												Pagati: t('pagamentiSpontanei.pagati')
											}}
										/>
									</div>
								)}
							/>
						</div>
						<div className="col-md-8 pos-deb-filter flex-nowrap">
							<div className="flex-grow-1">
								<Controller
									control={control}
									name="tipo"
									defaultValue="null"
									render={({ field: { onChange, value } }) => (
										<div className="bootstrap-select-wrapper input-con-descrizione" style={{ maxWidth: "640px" }}>
											<Select
												titolo={t('pagamentiSpontanei.tipoDebito')}
												valoreSelezionato={value}
												nome="tipo"
												onChange={onChange}
												scelte={paymentTypes?.reduce((acc, v) => ({
													...acc,
													[`${v.ente.codiceEnte}///${v.codiceTributo}`]: `${v.ente.intestatario.denominazione} - ${v.denominazione}`
												}), { null: t('pagamentiSpontanei.nessunFiltro') }) ?? {}}
											/>
										</div>
									)}
								/>
							</div>
							<div className="pos-deb-search-button">
								<Button
									color="primary"
									onClick={handleSubmit(searchFunction)}
								>
									{t('pagamentiSpontanei.buttonCerca')}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="container p-0">
					<div className="row my-4" style={{ margin: "0px" }}>
						<div className="col-12">{t('pagamentiSpontanei.risultati')} {numRisultati}</div>
					</div>
				</div>
				<div className={isLaptop ? "container bg-white pos-deb-container" : "container bg-white"}>
					{!data || status === "fetching"
						? (
							<>
								<div className="row mt-1 bg-white pt-4 pl-4 pr-4 pb-4 carrello-elemento-row">
									<div className="pageLoader" style={{ height: "50px", width: "50px" }} />
								</div>
							</>
						)
						: (
							<>
								<DataViewer head={tableHead} data={tableData} buttons={tableButtons} />
								<Paginazione
									paginaCorrente={data?.currentPage + 1}
									perPagina={pageSize}
									risultatiTotali={data?.totalResults}
									onPageChange={(p) => setPage(p)}
								/>
							</>
						)
					}
				</div>
			</section>
			<ModaleServizio
				isOpen={isOpenServ != null}
				setIsOpen={() => { setIsOpenServ(null); }}
				paymentData={isOpenServ}
			/>
			<ConfirmModal
				open={openConfirm}
				title={t('pagamentiSpontanei.eliminazioneDebito')}
				text={t('pagamentiSpontanei.text1')}
				onConfirm={deleteDebit}
				onDeny={() => setOpenConfirm(false)}
				confirmText={t('pagamentiSpontanei.elimina')}
			/>
			<ConfirmModal
				open={openFailDelete}
				title={t('pagamentiSpontanei.eliminazioneDebitoFallita')}
				text={t('pagamentiSpontanei.text2')}
				onConfirm={() => setOpenFailDelete(false)}
				confirmText="OK"
			/>
			<ConfirmModal
				open={openSuccessDelete}
				title={t('pagamentiSpontanei.debitoEliminato')}
				text={t('pagamentiSpontanei.text3')}
				onConfirm={() => setOpenSuccessDelete(false)}
				confirmText="OK"
			/>
		</>
	);
}
