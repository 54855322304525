/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import qs from "qs";
import {
	Alert,
	Divider,
	FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography
} from "@mui/material";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router-dom";

import ToolsArea from "../Components/ToolsArea";
import { useFetch } from "../../Hooks/useFetch";
import BigDataTable from "../Components/Tables/BigDataTable";
import DataTableColumn from "../Components/Tables/DataTableColumn";
import EnteSelect from "../Components/Forms/EnteSelect";
import RangePicker from "../Components/Forms/RangePicker";
import TributoSelect from "../Components/Forms/TributoSelect";
import StrutturaSelect from "../Components/Forms/StrutturaSelect";
import Export from "../Components/Export";
import TransazioneFields from "../Utils/TransazioneFields";
import { removeEmptyObjects } from "../../Utils/Object";
import PluginManager from "../../Plugins/PluginManager";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import VociExportFields from "../Utils/VociExportFields";
import DataLabel from "../../Utils/DataLabel";
import FiltersViewer from "../Components/FiltersViewer";

function TributoPick({ control, enteOperatore, fieldWatchName, name }) {
	const ente = useWatch({ control, name: fieldWatchName }) ?? enteOperatore;

	return (
		<Controller
			render={({ field: { onChange, value } }) => (
				<TributoSelect
					onChange={(v) => onChange(v)}
					value={value}
					style={{ flexShrink: 1 }}
					ente={ente}
					ricerca
				/>
			)}
			name={name}
			control={control}
		/>
	);
}

const StatoPagamento = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ flexGrow: 1 }} className={className}>
				<InputLabel id="statoPagamento-label">Stato pagamento</InputLabel>
				<Select labelId="statoPagamento-label" id="statoPagamento" label="Tipo voce" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value={false}>Da pagare</MenuItem>
					<MenuItem value>Pagato</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

const StatoIncasso = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ flexGrow: 1 }} className={className}>
				<InputLabel id="statoIncasso-label">Stato incasso</InputLabel>
				<Select labelId="statoIncasso-label" id="statoIncasso" label="Stato incasso" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value>Riaccreditato</MenuItem>
					<MenuItem value={false}>Non incassato</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

const Spontaneo = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ flexGrow: 1 }} className={className}>
				<InputLabel id="tipoPendenza-label">Tipo debito</InputLabel>
				<Select labelId="tipoPendenza-label" id="tipoPendenza" label="Tipo pendenza" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value>Spontaneo</MenuItem>
					<MenuItem value={false}>Posizione debitoria</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

const StrutturaOrganizzativa = ({ ctr, name, enteOperatore }) => {
	const ente = useWatch({
		control: ctr,
		name: "pendenza.debito.ente.codiceEnte",
		defaultValue: null
	});
	const { data: strutture } = useFetch('/struttura/list', enteOperatore ?? ente, 'GET');
	if (!strutture?.data) return "";
	const linearizeData = (rows) => rows.reduce((acc, d) => [
		...acc, d, ...linearizeData(d.figli)
	], []);
	const linearizedStrutture = linearizeData(strutture.data);
	return (
		<Controller
			name={name}
			defaultValue={null}
			control={ctr}
			render={({ field: { onChange, value } }) => (
				<div style={{ minWidth: "400px" }}>
					<StrutturaSelect
						onChange={onChange}
						value={value}
						strutture={(enteOperatore ?? ente) ? linearizedStrutture : []}
					/>
				</div>
			)}
		/>
	);
};

const Autenticazione = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ flexGrow: 1 }} className={className}>
				<InputLabel id="autenticazione-label">Tipo di autenticazione</InputLabel>
				<Select labelId="autenticazione-label" id="autenticazione" label="Tipo voce" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value={false}>Anonimo</MenuItem>
					<MenuItem value>Autenticato</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

function StatoPagamentoLabel({ statoPagamento, riaccreditato }) {
	switch (statoPagamento) {
		case "InCorso": return (<span style={{ background: "orange", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>In corso</b></span>);
		case "Eseguito":
			if (!riaccreditato) return (<span style={{ background: "#53befc", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Attesa accredito</b></span>);
			return (<span style={{ background: "#6fed7e", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Riaccreditato</b></span>);
		default:
		case "NonEseguito": return (<span style={{ background: "#bfc9d9", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Non pagato</b></span>);
	}
}

const FilterFields = ({ register: r, control: c, resetField }) => {
	const [personaFisica, setPersonaFisica] = useState(true);
	const allInterm = useWatch({ control: c, name: "transazione.allInterm" });
	const tributo = useWatch({ control: c, name: "pendenza.debito.tributo" });
	const enteOperatore = useWatch({ control: c, name: "enteOperatore" });
	const codiceEnte = useWatch({ control: c, name: "pendenza.debito.ente.codiceEnte" });

	const codiciTributo = Array.isArray(tributo?.codiceTributo)
		? tributo?.codiceTributo.map((t) => t?.codiceTributo).filter((codice) => codice)
		: [];

	const ultimoTributo = codiciTributo.length > 0 ? codiciTributo[codiciTributo.length - 1] : null;
	const { data: tributoFetchInt } = useFetch(tributo?.codiceTributo && `/tributo/${enteOperatore ?? codiceEnte}/${ultimoTributo}/all`, null, 'GET');

	const pm = new PluginManager();

	// - Recupero la configurazione del plugin e genero il plugin
	const pc = tributoFetchInt?.plugins?.find((p) => p["@class"].split('.').pop() === 'CampiConfiguration');
	const cp = pc ? pm.getPlugin("CAMPI", pc) : null;

	useEffect(() => {
		resetField("pendenza.debito.pluginData");
	}, [tributo?.codiceTributo]);

	useEffect(() => {
		resetField("pendenza.debito.tributo");
	}, [codiceEnte]);

	return (
		<Grid container direction="column" width="100%" maxWidth="1200px" rowGap={3}>
			<Grid container direction="row" backgroundColor="#E8EDFC" borderRadius="5px" padding="0px 20px 10px 20px" flexWrap="nowrap" columnGap={3}>
				{!enteOperatore && (
					<Controller
						control={c}
						name="pendenza.debito.ente.codiceEnte"
						render={({ field: { onChange, value } }) => (
							<EnteSelect onChange={onChange} value={value} />
						)}
					/>
				)}
				<TributoPick control={c} enteOperatore={enteOperatore} fieldWatchName="pendenza.debito.ente.codiceEnte" name="pendenza.debito.tributo.codiceTributo" />
				<StrutturaOrganizzativa name="pendenza.debito.struttura.cdr" ctr={c} enteOperatore={enteOperatore} />
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={3}>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Controller control={c} name="pendenza.debito.codiceDebito" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Id debito" variant="standard" style={{ flexShrink: 1, flexGrow: 1 }} />} />
					<RangePicker control={c} nameStart="pendenza.debito.inizioCreazione" nameEnd="pendenza.debito.fineCreazione" variant="standard" startText="Creazione da" endText="Creazione a" />
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Controller
						render={({ field: { onChange, value } }) => (
							<TextField
								onChange={(v) => onChange(v.target.value === "" ? null : v)}
								value={value ?? ''}
								variant="standard"
								style={{ flexShrink: 1, flexGrow: 1 }}
								label="IUV"
							/>
						)}
						name="pendenza.iuv"
						control={c}
					/>
					<RangePicker control={c} nameStart="pendenza.debito.inizioInsorgenza" nameEnd="pendenza.debito.fineInsorgenza" variant="standard" startText="Insorgenza da" endText="Insorgenza a" />
				</Grid>
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={2} alignItems="center">
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					<Grid container direction="row" margin="0px 0px 0px 0px" alignItems="center" flexShrink="1" maxWidth="220px" height="30px">
						{personaFisica ? "Giuridica" : <b>Giuridica</b>}
						<Switch onChange={(e, v) => setPersonaFisica(v)} checked={personaFisica} />
						{!personaFisica ? "Fisica" : <b>Fisica</b>}
						<Divider orientation="vertical" style={{ marginLeft: "20px" }} />
					</Grid>
					<Controller control={c} name="pendenza.debito.anagraficaDebitore.codiceFiscale" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Codice fiscale" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="pendenza.debito.anagraficaDebitore.partitaIva" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Partita IVA" variant="standard" style={{ flexGrow: 1 }} />} />
					{personaFisica && (<Controller control={c} name="pendenza.debito.anagraficaDebitore.zipCode" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Tax/health identification code" variant="standard" style={{ flexGrow: 1 }} />} />)}
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					{
						personaFisica && (
							<>
								<Controller control={c} name="pendenza.debito.anagraficaDebitore.cognome" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Cognome" variant="standard" style={{ flexGrow: 1 }} />} />
								<Controller control={c} name="pendenza.debito.anagraficaDebitore.nome" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Nome" variant="standard" style={{ flexGrow: 1 }} />} />
							</>
						)
					}
					{
						!personaFisica && (
							<Controller control={c} name="pendenza.debito.anagraficaDebitore.ragioneSociale" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Denominazione" variant="standard" style={{ flexGrow: 1 }} />} />
						)
					}
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					<Controller control={c} name="pendenza.debito.anagraficaDebitore.email" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Email del debitore" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="pendenza.debito.anagraficaDebitore.comune" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Comune del debitore" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="pendenza.debito.anagraficaDebitore.indirizzo" render={({ field: { value, onChange } }) => <TextField value={value ?? ''} onChange={onChange} label="Indirizzo del debitore" variant="standard" style={{ flexGrow: 1 }} />} />
				</Grid>
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={3} alignItems="center">
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Controller
						name="pendenza.debito.importoMin"
						control={c}
						required
						render={({ field: { onChange: change, value: v } }) => (
							<NumberFormat
								onValueChange={({ value }) => change(value)}
								value={v ?? ''}
								className=""
								label="Importo minimo"
								variant="standard"
								customInput={TextField}
								style={{ flexGrow: 1 }}
								prefix="€ "
								decimalScale={2}
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
					/>
					<Controller
						name="pendenza.debito.importoMax"
						control={c}
						required
						render={({ field: { onChange: change, value: v } }) => (
							<NumberFormat
								onValueChange={({ value }) => change(value)}
								value={v ?? ''}
								className=""
								label="Importo massimo"
								variant="standard"
								customInput={TextField}
								style={{ flexGrow: 1 }}
								prefix="€ "
								decimalScale={2}
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
					/>
					<Controller render={({ field: { onChange, value } }) => (<TextField onChange={(v) => onChange(v.target.value === "" ? null : v)} value={value ?? ""} variant="standard" label="Capitolo entrata" style={{ width: "200px" }} />)} name="pendenza.dettagli.0.capitoloEntrata" control={c} />
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					<RangePicker control={c} nameStart="pendenza.debito.pendenze.0.inizioScadenza" nameEnd="pendenza.debito.pendenze.0.fineScadenza" variant="standard" startText="Scadenza da" endText="Scadenza a" />
					<RangePicker control={c} nameStart="pendenza.debito.pendenze.0.inizioFineValidita" nameEnd="pendenza.debito.pendenze.0.fineFineValidita" variant="standard" startText="Fine validità da" endText="Fine validità a" />
				</Grid>
			</Grid>
			<Grid container direction="column" backgroundColor="#E8FCEC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={3} alignItems="center">
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Autenticazione ctr={c} name="transazione.autenticato" />
					<StatoIncasso ctr={c} name="riaccreditato" />
					<Grid container direction="row" margin="0px 0px 0px 0px" alignItems="center" flexShrink="1" maxWidth="270px" height="30px">
						<Divider orientation="vertical" style={{ marginRight: "20px" }} />
						{allInterm
							? <b>Pagamenti altri partner</b>
							: <>Pagamenti altri partner</>}
						<Switch inputProps={{ ...r('transazione.allInterm') }} checked={allInterm} />
					</Grid>
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Controller control={c} name="transazione.ccp" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Codice transazione (CCP)" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="versamento.sospeso.rendicontazione.identificativoFlusso" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Identificativo flusso" variant="standard" style={{ flexGrow: 1 }} />} />
					<RangePicker control={c} nameStart="transazione.inizioDataPagamento" nameEnd="transazione.fineDataPagamento" variant="standard" startText="Data pagamento da" endText="Data pagamento a" />
				</Grid>
			</Grid>
			{
				cp && (
					<Grid container direction="row" backgroundColor="#FCE8E8" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" columnGap={3} alignItems="center">
						{
							React.createElement(cp.searchFormRender({
								control: c,
								register: r,
								basePath: "pendenza.debito.",
								configuration: cp.configuration
							}), {})
						}
					</Grid>
				)
			}
		</Grid>
	);
};

export default function Pagamenti({ account }) {
	const queryInitial = {
		page: 0,
		pageSize: 20
	};

	const [selectedRows, setSelectedRows] = useState(new Map());
	const [refresh, setRefresh] = useState(0);
	const [openExport, setOpenExport] = useState(false);
	const history = useHistory();

	const selected = [...selectedRows];

	const enteOperatore = account.ente?.codiceEnte;

	const defaultQuery = useMemo(() => ({
		transazione: {
			inizioDataPagamento: moment().subtract(enteOperatore ? 30 : 1, 'days').format("YYYY-MM-DD")
		}
	}), []);

	const setQuery = useCallback(({ queryParameters, ...qUrl }) => {
		const queryString = qs.stringify({ ...queryInitial, ...removeEmptyObjects(qUrl) });
		history.push({ search: queryString });
	}, []);

	const { search } = useLocation();
	const query = useMemo(() => ({
		page: 0,
		pageSize: 20,
		queryParameters: ["ricercaGenerica"],
		enteOperatore,
		...defaultQuery,
		...(qs.parse(search.substring(1)))
	}), [search]);

	const pluginManager = new PluginManager();

	// - Recupero la configurazione del plugin e genero il plugin
	const tribCod = query?.pendenza?.debito?.tributo?.codiceTributo.length > 0 ? (query?.pendenza?.debito?.tributo?.codiceTributo ?? null) : null;
	const codiciTributo = tribCod ? tribCod.map((t) => t?.codiceTributo).filter((codice) => codice) : [];

	const { data: tributoFetch } = useFetch(tribCod && `/tributo/${enteOperatore ?? query?.pendenza?.debito?.ente?.codiceEnte}/${codiciTributo}/all`, null, 'GET');

	const pluginConf = tributoFetch?.plugins?.find((p) => p["@class"].split('.').pop() === 'CampiConfiguration');
	const currPlugin = pluginConf ? pluginManager.getPlugin("CAMPI", pluginConf) : null;
	const pluginCols = (currPlugin && currPlugin.getColumnsBackoffice(currPlugin.configuration)) ?? [];

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("pagamenti", [
		{
			key: "dettagli",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => {
				if (row.codiceDebito) {
					return (
						<IconButton
							style={{ fontSize: "20px" }}
							onClick={() => {
								history.push(encodeURI(`debito/${encodeURIComponent(row.codiceEnte)}/${encodeURIComponent(row.codiceTributo)}/${encodeURIComponent(row.codiceDebito).replaceAll("%2F", "%252F")}${row.altDebito ? `/${row.altDebito}` : ""}`));
							}}>
							<i className="fa fa-search" />
						</IconButton>
					);
				}
				return <></>;
			}
		},
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", sortColumn: "pendenza.debito.ente.codiceEnte", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "codiceTributo", name: "Tributo", sortColumn: "pendenza.debito.tributo.idTributo.codiceTributo", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "cdr", name: "CDR", sortColumn: "pendenza.debito.struttura.idStruttura.cdr", frozen: false /*true*/, sortable: true, draggable: true, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{ key: "codiceDebito", name: "Codice debito", sortColumn: "pendenza.debito.idDebito.codiceDebito", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 250, width: 250 },
		{ key: "rata", name: "Rata", sortColumn: "pendenza.idPendenza.numeroRata", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 60, width: 60 },
		{ key: "iuv", name: "IUV", sortColumn: "pendenza.iuv", sortable: true, frozen: false /*true*/, draggable: false, resizable: false, highlight: true, minWidth: 160, width: 160 },

		{
			key: "stato",
			name: "Stato",
			sortColumn: "stato",
			sortable: true,
			frozen: false,
			draggable: true,
			highlight: false,
			resizable: false,
			minWidth: 150,
			width: 150,
			formatter: ({ row }) => <StatoPagamentoLabel statoPagamento={row.stato.stato} riaccreditato={row.stato.riaccreditato} />
		},
		{ key: "pagamento", name: "Pagamento", sortColumn: "tsInserimento", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 150, width: 150 },
		{ key: "nominativo", name: "Nominativo", frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "codiceFiscale", name: "Codice fiscale", sortColumn: "pendenza.debito.intestatarioDebitore.codiceFiscale", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 170, width: 170 },
		{ key: "importo", name: "Importo", sortColumn: "importo", frozen: false, draggable: true, sortable: true, highlight: true, resizable: true, minWidth: 80, width: 80 },
		{ key: "codTransazione", name: "Codice transazione", sortColumn: "transazione.ccp", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 150, width: 150 },
		{ key: "identificativoFlusso", name: "ID flusso", sortColumn: "versamento.sospeso.flusso.identificativoFlusso", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 250, width: 250 },
		{ key: "comune", name: "Comune", sortColumn: "pendenza.debito.intestatarioDebitore.comune", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 150, width: 150 },
		{ key: "indirizzo", name: "Indirizzo", sortColumn: "pendenza.debito.intestatarioDebitore.indirizzo", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 300, width: 300 },
		{ key: "cfVersante", name: "C.F. Versante", sortColumn: "transazione.utenteVersante.codiceFiscale", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 170, width: 170 },
		{ key: "insorgenza", name: "Insorgenza", sortColumn: "pendenza.debito.dataInsorgenza", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 150, width: 150 },
		{ key: "voci", name: "N° Voci", frozen: false, draggable: true, highlight: false, resizable: false, minWidth: 0, width: 60 },
		{ key: "entiBeneficiari", name: "Altri enti beneficiari", sortColumn: "transazione.entiBeneficiari", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 0, width: 300 },
		{ key: "causale", name: "Causale", sortColumn: "pendenza.causale", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 500 },
		...pluginCols
	], ["dettagli", !enteOperatore && "codiceEnte", "codiceTributo", "cdr", "codiceDebito", "rata", "iuv", "stato",
		"importo", "voci", "codTransazione", "codiceFiscale", "nominativo", "comune", "indirizzo", "cfVersante", "pagamento", "insorgenza", "entiBeneficiari", "causale", ...pluginCols.map((r) => r.key)], []);

	const ultimoTributo = codiciTributo.length > 0 ? codiciTributo[codiciTributo.length - 1] : null;

	const updatedQuery = {
		...query,
		pendenza: removeEmptyObjects({
			...query.pendenza,
			debito: {
				...query.pendenza?.debito,
				tributo: {
					codiceTributo: ultimoTributo
				},
				tributi: tribCod
			}
		})
	};

	const { data, status } = useFetch('/pagamento/query', null, 'POST', { ...updatedQuery, orderBy: sortFilter }, refresh);

	//const { data, status } = useFetch('/pagamento/query', null, 'POST', { ...query, orderBy: sortFilter }, refresh);

	const tableData = data?.data?.map((r) => ({
		id: r.id,
		codiceEnte: r.pendenza?.debito?.codiceEnte ?? "",
		codiceTributo: r.pendenza?.debito?.codiceTributo ?? "",
		cdr: r.pendenza?.debito.cdr,
		codiceDebito: r?.pendenza?.debito?.codiceDebito,
		altDebito: r?.pendenza?.debito?.altDebito ?? "",
		rata: r?.pendenza?.numeroRata === 0 ? "Unica" : (r?.pendenza?.numeroRata?.toString() ?? ""),
		iuv: r?.pendenza?.iuv ?? r?.sospeso?.iuv,

		stato: { riaccreditato: r?.riaccreditato, stato: r.stato },
		importo: `€ ${r?.importo}`,
		voci: r?.pendenza?.dettagli?.length ?? r?.versamenti?.length,
		codTransazione: r?.transazione?.ccp ?? r?.identificativoRicevuta,
		codiceFiscale: r?.pendenza?.debito?.anagraficaDebitore?.codiceFiscale || r?.pendenza?.debito?.anagraficaDebitore?.partitaIva,
		nominativo: `${r?.pendenza?.debito?.anagraficaDebitore?.nome ?? ""} ${r?.pendenza?.debito?.anagraficaDebitore?.cognome ?? ""} ${r?.pendenza?.debito?.anagraficaDebitore?.ragioneSociale || ""}`,
		comune: r?.pendenza?.debito?.anagraficaDebitore?.comune ?? "",
		indirizzo: r?.pendenza?.debito?.anagraficaDebitore?.indirizzo ?? "",
		cfVersante: r?.transazione?.versante?.codiceFiscale || r?.transazione?.versante?.codiceFiscale,
		pagamento: r?.timestampPagamento ? moment(r?.timestampPagamento).format("DD/MM/yyyy HH:mm:ss") : "N.D.",
		insorgenza: r?.pendenza?.debito?.dataInsorgenza ? moment(r?.pendenza?.debito?.dataInsorgenza).format("DD/MM/yyyy") : "N.D.",
		identificativoFlusso: r?.versamenti?.reduce((acc, v) => `${acc}${v?.sospeso?.rendicontazione?.identificativoFlusso ?? ""}  `, "") ?? "",
		entiBeneficiari: r?.transazione?.entiBeneficiari?.filter((e) => e !== account?.ente?.intestatario?.codiceFiscale).toString() ?? "",
		causale: r?.pendenza?.causale,
		...(Object.entries(r.pendenza?.debito?.pluginData?.find((p) => p.codicePlugin === "CAMPI")?.valueMap ?? {})?.reduce((acc, [k, v]) => {
			acc[`p_${k}`] = v;
			return acc;
		}, {}))
	}));

	return (
		<div>
			<Export
				open={openExport}
				columns={[...Object.values(TransazioneFields), ...(pluginCols?.map((f) => new DataLabel(f.name, `PLUGIN|${f.intKey}`)) ?? [])]}
				queryFilters={{ ...updatedQuery, pageSize: null, page: null }}
				onDeny={() => setOpenExport(false)}
				onSuccess={() => history.push("/backoffice/export")}
				totalResults={data?.totalResults ?? 0}
				type={{
					name: "Pagamenti",
					apiName: "pagamenti",
					path: "it.ras.pagopa.shared.dto.system.prenotazione.PrenotazionePagamento"
				}}
				initialSelected={[
					TransazioneFields.insorgenza, TransazioneFields.dataPagamento,
					TransazioneFields.descrizioneCanale, TransazioneFields.importo,
					TransazioneFields.codiceFiscaleVers, TransazioneFields.codiceFiscaleDeb,
					TransazioneFields.nomeDeb, TransazioneFields.comuneResidenzaDeb,
					TransazioneFields.indirizzoResidenzaDeb,
					TransazioneFields.ccp, TransazioneFields.dataRegolamento,
					TransazioneFields.identificativoFlusso,
					TransazioneFields.emailVers, TransazioneFields.stato,
					TransazioneFields.riaccreditato, TransazioneFields.codiceDebito,
					TransazioneFields.iuv, TransazioneFields.cdrStruttura, TransazioneFields.causaleDebito,
					TransazioneFields.causalePendenza, TransazioneFields.note
				]}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				queryParameters={[
					"ricercaGenerica"
				]}
				disableDelete
				disableEdit
				disableNew
				enableExport
				exportCallback={() => setOpenExport(true)}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				actualFilters={query}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
				callStatus={status}
			/>
			<FiltersViewer filters={query} setFilters={setQuery} />
			<br />
			{!data || status === "fetching" ? <SpinnyPage /> : (
				<DataGrid
					columns={visibleColumns}
					rows={tableData}
					query={(query?.query ?? "").split(" ")}
					onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
					rowKeyGetter={(row) => row.id}
					totalResults={data.totalResults}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					setPage={(page) => setQuery({ ...query, page })}
					currPage={parseInt(query?.page, 10)}
					sortData={sort}
					onSortDataChange={setSort}
				/>
			)}
			<br />
			<div style={{ display: "flex" }}>
				{currPlugin && <Alert severity="info">* Campo non standard relativo al form personalizzato.</Alert>}
			</div>
			<br />
			<br />
		</div>
	);
}
