import React from "react";
import { ModalHeader, ModalBody, ModalFooter, Modal } from "design-react-kit";
import RicercaGenerale from "./RicercaGenerale";


export default function ModaleRicerca(props) {
	const {
		t,
		isOpen,
		setIsOpen
	} = props;

	return (
		<Modal isOpen={isOpen} toggle={() => setIsOpen(false)} className="modale-checkout">
			<ModalHeader toggle={() => setIsOpen(false)} id="ricerca">
				{t('ricerca.titoloModalRicerca')}
			</ModalHeader>
			<ModalBody className="py-4">
				<RicercaGenerale onClose={() => setIsOpen(false)} />
			</ModalBody>
			<ModalFooter />
		</Modal>
	);
}
